/*CPF or CNPJ*/
var cpfcnpjtm=null;
function mascaraCPFCNPJ(o, f) {
    v_obj = o;
    v_fun = f;
    cpfcnpjtm=setTimeout(execmascara, 1);
}
function execmascara() {
    v_obj.value = v_fun(v_obj.value);
}
function cpfCnpj(v) {
    // Remove tudo o que não é dígito
    v = v.replace(/\D/g, "");
    if (v.length <= 11) { // CPF
        // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        // Coloca um ponto entre o terceiro e o quarto dígitos
        // de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        // Coloca um hífen entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else if (v.length > 13) { // CNPJ
        v = v.substr(0,14);
        // Coloca ponto entre o segundo e o terceiro dígitos
        v = v.replace(/^(\d{2})(\d)/, "$1.$2");
        // Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        //Coloca uma barra entre o oitavo e o nono dígitos
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
        //Coloca um hífen depois do bloco de quatro dígitos
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }
    return v;
}
$(function () {
    /*Centralized Modals*/
    function adjustModalMaxHeightAndPosition(windowobj) {

        windowobj = windowobj ? windowobj : window;

        $('.modal').each(function() {
            if($(this).hasClass('in') == false) {
                $(this).show();
            };
            var contentHeight = $(windowobj).height() - 60;
            var headerHeight = $(this).find('.modal-header').outerHeight() || 2;
            var footerHeight = $(this).find('.modal-footer').outerHeight() || 2;

            $(this).find('.modal-content').css({
                'max-height': function () {
                    return contentHeight;
                }
            });

            $(this).find('.modal-body').css({
                'max-height': function () {
                    return (contentHeight - (headerHeight + footerHeight));
                }
            });
            // Dividing by two centers the modal exactly, but dividing by three
            // or four works better for larger screens.
            var dialog = $(this).find('.modal-dialog');
            dialog.css("margin-top", Math.max(0, ($(window).height() - dialog.height()) / 2));

            if($(this).hasClass('in') == false) {
                $(this).hide();
            };
        });
    }
    var decodeEntities = (function() {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');

        function decodeHTMLEntities (str) {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }

            return str;
        }

        return decodeHTMLEntities;
    })();
    jQuery.decodeEntities = decodeEntities;
    /*Message Modals*/
    function modal_alert(modal, msg, title, btn, severity, cmd, options) {
        modal.find('.modal-severity').addClass('modal-' + severity);
        modal.find('.modal-title').html(title);
        modal.find('.modal-body').html(msg);
        modal.find('.modal-footer').html(
            '<button type="button" data-dismiss="modal" aria-label="' +
            btn    + '" class="btn btn-default">' + btn + '</button>'
        );
        modal.find('.modal-footer').css('display','block');
        if (options && typeof(options) == 'object') {
                modal.modal(options);
            }
            if (cmd) {
                modal.find('.modal-footer>button[data-dismiss="modal"]').click(cmd);
            }
        return modal.modal('show');
    }
    function modal_ask(modal, msg, title, btn_ok, btn_cancel, severity, cmd_ok, cmd_cancel, options) {
        modal.modal('hide');
        modal.find('.modal-severity').attr('class', 'modal-severity modal-' + severity);
        modal.find('.modal-title').html(title);
        modal.find('.modal-body').html($.decodeEntities(msg));
        modal.find('.modal-footer').html(
            '<button type="button" aria-label="' +
            btn_ok    + '" class="btn btn-success">' + btn_ok + '</button>' +
            '<button type="button" data-dismiss="modal" aria-label="' +
            btn_cancel    + '" class="btn btn-default">' + btn_cancel + '</button>'
        );
        modal.find('.modal-footer').css('display','block');
        if (options && typeof(options) == 'object') {
                modal.modal(options);
            }
        if (cmd_ok) {
            modal.find('.modal-footer>button:not([data-dismiss="modal"])').click(function(){cmd_ok();modal.modal('hide');});
        }
        if (cmd_cancel) {
            modal.find('.modal-footer>button[data-dismiss="modal"]').click(cmd_cancel);
        }
        return modal.modal('show');
    }
    function notify(type, icon, title, message, url, target, dismiss) {
        dissmis = dismiss == null ? true : parseInt(dismiss) > 0;
        message = '<br />' + decodeEntities(message);
        data = {
            title: title,
            message: message
        };
        if (icon) {
            if (type == 'success') {
                icon = 'ok';
            } else if (type == 'danger') {
                icon = 'exclamation';
            } else {
                icon = type;
            }
            data.icon = 'glyphicon glyphicon-' + icon +  '-sign';
        }
        if (url) {
            data.url = url;
            if (target) {
                data.target = target;
            }
        }
        options = {
            element: 'body',
            type: type,
            allow_dismiss: dismiss,
            placement: {
                from: 'top',
                align: 'right',
            },
            delay: 3000,
            animate: {
                enter: 'animated fadeInDown',
                exit: 'animated fadeOutUp'
            },
            icon_type: 'class',
        };
        return $.notify(data, options);
    }
    jQuery.fn.extend({
        info: function (msg, title, dismiss, cmd, options) {
            if (cmd)
                return modal_alert(this, msg, title, dismiss, 'info', cmd, options);
            else
                return notify('info', true, title, msg);
        },
        danger: function (msg, title, dismiss, cmd, options) {
            if (cmd)
                return modal_alert(this, msg, title, dismiss, 'danger', cmd, options);
            else
                return notify('danger', true, title, msg);
        },
        warning: function (msg, title, dismiss, cmd, options) {
            if (cmd)
                return modal_alert(this, msg, title, dismiss, 'warning', cmd, options);
            else
                return notify('warning', true, title, msg);
        },
        success: function (msg, title, dismiss, cmd, options) {
            if (cmd)
                return modal_alert(this, msg, title, dismiss, 'success', cmd, options);
            else
                return notify('success', true, title, msg);
        },
        alert: function (severity, msg, title, dismiss, cmd, options) {
            return modal_alert(this, msg, title, dismiss, severity, cmd, options);
            //return eval('this.' + severity + '(this, msg, title, dismiss, severity, cmd, options)');
        },
        notification: function (severity, msg, title, icon, dismiss) {
            return notify(severity, icon, title, msg, null, null, dismiss);
        },
        ask: function (severity, msg, title, ok, cancel, cmd_ok, cmd_cancel, options) {
            return modal_ask(this, msg, title, ok, cancel, severity, cmd_ok, cmd_cancel, options);
        },
        adjustModalMaxHeightAndPosition: adjustModalMaxHeightAndPosition,
        fillModal: function (data) {
            for (prop in data) {
                val = eval('return data.' + prop);
                input = $(this).find('input[name*="' + prop + '"]');
                select = $(this).find('select[name="' + prop + '"]');
                tarea = $(this).find('textarea[name="' + prop + '"]');
                if (i.size()) {
                    type = input.attr('type');
                    if (type == 'text' || type == 'hidden') {
                        input.val(val);
                    } else {
                        if (typeof(val) == 'string' || typeof(val) == 'number') {
                            val = [val];
                        }
                        for (i = 0; i < val.length; i++ ) {
                            input.filter('input[value="' + val + '"]').attr('checked','checked');
                        }
                    }
                }
                if (s.size()) {
                    select.find('option[value=' + val + ']').attr('selected','selected')
                }
            }
        },
        cleanErrors: function () {
            /* Clean errors */
            $(this).find('input[type="text"], input[type="password"], input[type="email"], input[type="hidden"], textarea').on('keyup change', function () {
                if ($('#error-' + this.id).size()) {
                    $('#div-' + this.id).removeClass('has-error div-error');
                    $('#error-' + this.id).addClass('hide');
                }
            });
            $(this).find('select').change(function () {
                if ($('#error-' + this.id).size()) {
                    $('#div-' + this.id).removeClass('has-error div-error');
                    $('#error-' + this.id).addClass('hide');
                }
            });
            $(this).find('input[type="checkbox"]').on('ifClicked click', function () {
                if ($('#error-' + this.id).size()) {
                    $('#div-' + this.id).removeClass('has-error div-error');
                    $('#error-' + this.id).addClass('hide');
                }
            });
            $(this).find('input[type="radio"]').on('click', function () {
                tmp = this.id.split('-');
                id = '';
                for (i = 0; i < tmp.length - 1; i++) {
                    if (i) {
                        id = id + '-';
                    }
                    id = id + tmp[i];
                }
                if ($('#error-' + id).size()) {
                    $('#div-' + id).removeClass('has-error div-error');
                    $('#error-' + id).addClass('hide');
                }
            });
        }
    });
    $.pageOverlay = {
        c: 1,
        show: function () {
            this.c++;
            $('#page-overlay').removeClass('hide');
	    setTimeout(() => {this.hide()},2500);
        },
        hide: function () {
            if (this.c > 0) {
                this.c--;
            }
            if (this.c < 1) {
                this.c = 0;
                $('#page-overlay').addClass('hide');
            }
        },
        count: function () {
            return this.c;
        }
    };
    $.href = function (href) {
        $.pageOverlay.show();
        location.href = href;
    };
    $(document).ready(function () {
        if ($('#ticket-edit-modal').size()) {
            i = $('input[name="ticket_id"]');
            if (i.size()) {
                i.parent().attr('id','comment');
            }
        }
        /* Format */
        $('.cpfcnpj').each(function () {
            if ($(this).filter('input').size()) {
                mascaraCPFCNPJ($(this)[0],cpfCnpj);
                $(this).on('input keyup',function () {
                    mascaraCPFCNPJ(this,cpfCnpj);
                }).blur(function () {
                    cpfcnpjtm=null;
                    clearTimeout(cpfcnpjtm);
                });
            } else {
                t=$(this);
                if (t.html().length) {
                    t.html(cpfCnpj(t.html()));
                } else {
                   t.val(cpfCnpj(t.val()));
                }
            }
        });
        var formatPhone = function (phone) {
            res = phone;
            phone = phone.replace('+','').replace('(','').replace(')','').replace('-','').replace('_','');
            if (phone.length > 11 && phone.length < 14) {
                phone = phone.substr(2);
            }
            if (phone.length > 9 && phone.length < 12) {
                res = '(' + phone.substring(0,2) + ')' + phone.substring(2,6);
                if (phone.length == 11) {
                    res = res + phone[6] + '-' + phone.substring(7);
                } else {
                    res = res + '-' + phone.substring(6);
                }
            }
            return res;
        }
        $('.phone').each(function () {
            t=$(this);
            if (t.html().length) {
                t.html(formatPhone(t.html()));
            } else {
                t.val(formatPhone(t.val()));
            }
        });
        var formatCEP = function (cep) {
            cep = cep.replace('-','').replace('_','');
            if (cep.length > 7 && cep.length < 9) {
                cep = cep.substring(0,5) + '-' + cep.substring(5);
            }
            return cep;
        }
        $('.cep').each(function () {
            t=$(this);
            if (t.html().length) {
                t.html(formatCEP(t.html()));
            } else {
                t.val(formatCEP(t.val()));
            }
        });
        $(window).resize(function(){
            adjustModalMaxHeightAndPosition(this);
        });
        $('.modal').on({
            'show.bs.modal':function(){adjustModalMaxHeightAndPosition(window);},
            'shown.bs.modal':function(){adjustModalMaxHeightAndPosition(window);}
        });
        msg = $('#quotem-message');
        if (msg.size()) {
            $('#quotem-message').css('display','none');
            $.ajax({
                url: '/message',
                method: 'get',
                success: function (data) {
                    if (data.message.length) {
                        cls = data.message[0];
                        eval(
                            '$("#modal-default").' + cls + '("' +
                            msg.html().replace(/"/g, '\\\"') + '","' +
                            msg.attr('data-title').replace(/"/g, '\\\"') + '","' +
                            msg.attr('data-dismiss').replace(/"/g, '\\\"') + '","' +
                            (msg.attr('data-modal') !== undefined ? msg.attr('data-modal').replace(/"/g, '\\\"') : '') + '","' +
                            '")'
                            //'").modal("show")'
                        );
                    }
                 }
            });
        }
        /* Button crete, edit, destroy */
        $('.btn-create-resource').each(function (e) {
            $(this).click(function () {
                $.href('/' + this.id.split('-').slice(1).join('/'));
            });
        });
        $('.btn-show-resource').each(function () {
            $(this).click(function () {
                $.href('/' + this.id.split('-').slice(1).join('/'));
            });
        });
        $('.btn-edit-resource').each(function () {
            $(this).click(function () {
                $.href('/' + this.id.split('-').slice(1).join('/'));
            });
        });
        $('.btn-destroy-resource').each(function (e) {
            $(this).click(function (e) {
                var button = $(this);
                var url = '/' + this.id.split('-').slice(2).join('/');
                var form = $('<form method="post">').append($('<input type="hidden" name="_method" value="delete">')).append($('input[name="_token"]:eq(0)')).attr('action',url);
                $('#modal-default').ask(
                    'warning',
                    $(this).attr('data-question'),
                    $(this).attr('data-title'),
                    $(this).attr('data-confirm'),
                    $(this).attr('data-cancel'),
                    function () {
                        button.after(form);
                        button.parent().find('form')[0].submit();
                    }
                );
            });
        });
        $('.btn-save-resource').each(function (e) {
            $(this).click(function () {
                $('#' + this.id.replace('-submit','')).submit();
            });
        });
        $('.btn-flash-clear').click(function () {
            var frm = $(this).parents('form:eq(0)');
            var btn = $(this);
            var token = frm.find('input[name="_token"]:eq(0)').val();
            $.ajax({
                method: 'post',
                url: '/clear',
                data: {
                    id: $(this).data('flash'),
                    files: $(this).data('files'),
                    _token: token,
                },
                success: function () {
                    location.reload(true, true);
                }
            });
        });
        /* iCheck */
        if ($('input[type="checkbox"]:not(.switch)').size()) {
            $('input[type="checkbox"]:not(.switch)').iCheck({
                checkboxClass: 'icheckbox_square-blue',
                radioClass: 'iradio_square-blue',
                increaseArea: '20%' // optional
            });
        }
        /* Select 2 */
        if ($('.select2').size()) {
            $('.select2').not('.select2-default').addClass('select2-initilized').select2({minimumResultsForSearch: Infinity});
            $('.select2').filter('.select2-default').select2();
        }
        /* Bootstrap Switch */
        if ($('.switch').size()) {
            $('.switch').bootstrapSwitch();
        }
        /* Spinner */
        if ($('.spinner').size()) {
            $('.spinner').spinner();
        }
        /* Summernote*/
        window.summernote_default = function (editor, content) {
            if (content) {
                editor.summernote('destroy');
                editor.val(content.val());
            }
            editor.summernote({
                placeholder: editor.attr('placeholder') ? editor.attr('placeholder') : '',
                height: editor.attr('data-height') ? eval(editor.attr('data-height')) : (editor.attr('rows') ? eval(editor.attr('rows') + '*2') + 'em' : null),
                fontNames:
                editor.attr('data-font-names')
                    ? eval('[\'' + editor.attr('data-font-names').replace("\n",'').replace(/ ?, ?/g,'\',\'') + '\']')
                    : [
                    'Arial',
                    'Arial Black',
                    'Comic Sans MS',
                    'Courier New',
                    'Helvetica',
                    'Impact',
                    'Times New Roman',
                    'Verdana',
                    'Source Sans Pro',
                    'Lato',
                    'Raleway'
                ],
                lang: summernote_lang,
                callbacks: {
                    onInit: function () {
                        $('.note-editor .modal').on({
                            'show.bs.modal':function(){adjustModalMaxHeightAndPosition(window);},
                            'shown.bs.modal':function(){adjustModalMaxHeightAndPosition(window);}
                        });
                    }
                }
            });
        };
        window.summernote_ticket = function (editor, content) {
            if (content) {
                editor.summernote('destroy');
                editor.val(content.val());
            }
            editor.summernote({
                placeholder: editor.attr('placeholder') ? editor.attr('placeholder') : '',
                height: editor.attr('data-height') ? eval(editor.attr('data-height')) : (editor.attr('rows') ? eval(editor.attr('rows') + '*2') + 'em' : null),
                fontNames:
                    editor.attr('data-font-names')
                        ? eval('[\'' + editor.attr('data-font-names').replace("\n",'').replace(/ ?, ?/g,'\',\'') + '\']')
                        : [
                            'Arial',
                            'Arial Black',
                            'Comic Sans MS',
                            'Courier New',
                            'Helvetica',
                            'Impact',
                            'Times New Roman',
                            'Verdana',
                            'Source Sans Pro',
                            'Lato',
                            'Raleway'
                        ],
                lang: summernote_lang,
                toolbar: [
                    ["style", []],
                    ["font", ["bold", "underline", "italic", "clear"]],
                    ["color", ["color"]],
                    ["para", ["ul", "ol", "paragraph"]],
                    ["table", []],
                    ["insert", ["link", "picture"]],
                    ["view", []]
                ],
                callbacks: {
                    onInit: function () {
                        $('.note-editor .modal').on({
                            'show.bs.modal':function(){adjustModalMaxHeightAndPosition(window);},
                            'shown.bs.modal':function(){adjustModalMaxHeightAndPosition(window);}
                        });
                    }
                }
            });
        };
        $('.summernote').each(function () {
            window.summernote_default($(this));
        });
        $('.summernote-editor').each(function () {
            window.summernote_ticket($(this));
        });
        /* Text area counters */
        $('textarea').each(function(){
            var id=this.id;
            var self=$(this);
            var counter=$('#' + id + '-counter');
            if (id && counter.size() && self.attr('maxlength') && parseInt(self.attr('maxlength')) > 0) {
                var maxlength=parseInt(self.attr('maxlength'));
                counter.html(maxlength - $(this).val().length);
                self.keyup(function(e){
                   counter.html(maxlength - $(this).val().length);
                });
            }
        });
        /* Clean errors */
        $(document).cleanErrors();
        // scroll to top
        var slideToTop = $("<div />");
        slideToTop.html('<i class="fa fa-chevron-up"></i>');
        slideToTop.css({
            position: 'fixed',
            bottom: '10px',
            right: '10px',
            width: '30px',
            height: '30px',
            color: '#eee',
            'font-size': '',
            'line-height': '30px',
            'text-align': 'center',
            'background-color': '#222d32',
            cursor: 'pointer',
            'border-radius': '5px',
            'z-index': '99999',
            opacity: '.7',
            'display': 'none'
        });
        slideToTop.on('mouseenter', function() {
            $(this).css('opacity', '1');
        });
        slideToTop.on('mouseout', function() {
            $(this).css('opacity', '.7');
        });
        $('.wrapper').append(slideToTop);
        var minscroll = 200;
        if ($(window).scrollTop() >= minscroll) {
            if (!$(slideToTop).is(':visible')) {
                $(slideToTop).fadeIn(500);
            }
        }
        $(window).scroll(function() {
            if ($(window).scrollTop() >= minscroll) {
                if (!$(slideToTop).is(':visible')) {
                    $(slideToTop).fadeIn(500);
                }
            } else {
                $(slideToTop).fadeOut(500);
            }
        });
        $(slideToTop).click(function() {
            $('html, body').animate({
                scrollTop : 0
            }, 500);
        });
        $(".sidebar-menu li:not(.treeview) a").click( function() {
            var $this = $(this);
            var target = $this.attr("href");
            if (typeof target === 'string') {
                $("body").animate(
                    {
                        scrollTop : ($(target)
                                .offset().top)
                                + "px"
                    },
                    500
                );
            }
        });
        $(window).resize(function () {
            $('input[text-format="date"]').each(function () {
                if ($(this).attr('name').length) {
                    $(this)
                        .attr('data-provider','datetimepicker')
                        .datetimepicker({
                            locale: 'pt-BR',
                            format: 'DD/MM/YYYY'
                        }).on('dp.show', function () {
                        if($('.modal-body').size()) {
                            $('.modal-body').css('overflow', 'inherit');
                        }
                    }).on('dp.hide', function () {
                        if($('.modal-body').size()) {
                            $('.modal-body').css('overflow', null);
                        }
                    });
                }
            });
            $('input[text-format="datetime"]').each(function () {
                if ($(this).attr('name').length) {
                    if(!$(this).data('DateTimePicker')) {
                        $(this)
                            .attr('data-provider','datetimepicker')
                            .datetimepicker({
                                locale: 'pt-BR',
                                sideBySide: $('#content').innerWidth() >= 1024,
                            });

                    } else {
                        options = jQuery.extend({}, $(this).data('DateTimePicker').options);
                        options.sideBySide = $('#content').innerWidth() >= 1024;
                        $(this).data('DateTimePicker').destroy();
                        $(this).datetimepicker(options);
                    }
                    $(this).data('DateTimePicker').options.sideBySide = $('#content').innerWidth() >= 1024;
                }
            });
            $('input[text-format="daterange"]').each(function () {
                $(this).off('focus.daterange');
                $(this).on('focus.daterange', function () {
                    options = {
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: 1,
                        locale: {
                            format: "DD/MM/YYYY HH:mm",
                            separator: " - ",
                            applyLabel: "OK",
                            cancelLabel: "Cancela",
                            fromLabel: "De",
                            toLabel: "Até",
                            weekLabel: "S",
                            daysOfWeek: [
                                "Dom",
                                "Seg",
                                "Ter",
                                "Qua",
                                "Qui",
                                "Sex",
                                "Sab"
                            ],
                            monthNames: [
                                "Janeiro",
                                "Fevereiro",
                                "Março",
                                "Abril",
                                "Maio",
                                "Junho",
                                "Julho",
                                "Agosto",
                                "Setembro",
                                "Outubro",
                                "Novembro",
                                "Dezembro"
                            ],
                            firstDay: 1
                        },
                    }
                    if ($(this).attr('data-mindate')) {
                        options.minDate = new Date($(this).attr('data-mindate'));
                    }
                    if ($(this).attr('data-startdate')) {
                        options.startDate = new Date($(this).attr('data-startdate'));
                        if (options.minDate && options.minDate.getTime() > options.startDate.getTime()) {
                            options.startDate = options.minDate;
                        }
                        if (!options.endDate || options.endDate.getTime() < options.startDate.getTime()) {
                            options.endDate = new Date(options.startDate.getTime() + (24 * 3600 * 1000));
                        }
                    } else {
                        if (options.minDate) {
                            options.startDate = options.minDate;
                        }
                    }
                    if ($(this).attr('data-enddate')) {
                        options.endDate = new Date($(this).attr('data-enddate'));
                        if (options.minDate && options.minDate.getTime() > options.endDate.getTime()) {
                            options.endDate = new Date(options.minDate.getTime() + (24 * 3600 * 1000));
                        }
                        if (!options.startDate) {
                            options.startDate = options.endDate;
                        }
                    } else {
                        if (options.startDate) {
                            options.endDate = new Date(options.startDate.getTime() + (24 * 3600 * 1000));
                        }
                    }
                    if ($(this).attr('data-startdate')) {
                        options.startDate = new Date($(this).attr('data-startdate'));
                        if (options.minDate && options.minDate.getTime() > options.startDate.getTime()) {
                            options.startDate = options.minDate;
                        }
                        if (!options.endDate || options.endDate.getTime() < options.startDate.getTime()) {
                            options.endDate = new Date(options.startDate.getTime() + (24 * 3600 * 1000));
                        }
                    }
                    $(this).attr('data-startdate',null);
                    $(this).attr('data-mindate',null);
                    $(this).attr('data-enddate',null);
                    $(this).daterangepicker(options).data('daterangepicker').show();
                    $(this).trigger('daterange.focus');
                });
                var clear = $(this).parent().find('.datetime-clear');
                var changeDateRange = function (e) {
                    if (e.target.value) {
                        $(e.target).parent().find('.datetime-clear').removeClass('hide');
                    } else {
                        $(e.target).parent().find('.datetime-clear').addClass('hide');
                    }
                    $(this).trigger('daterange.change');
                };
                if (clear.size()) {
                    $(this).off('change.daterange');
                    $(this).on('change.daterange', changeDateRange);
                    clear.off('click.daterange');
                    clear.on('click.daterange', function () {
                        item = $(this).parent().parent().find('[text-format="daterange"]').val('');
                        item.trigger('daterange.clear');
                        item.trigger('daterange.change');
                    });
                    changeDateRange({target:this});
                }
            });
        });
        $('.select2ajax').each(function () {
            url = $(this).data('remote');
            width = $(this).data('width');
            token = $(this).data('token');
            $(this).select2({
                ajax: {
                    minimumInputLength: 3,
                    url: url,
                    method: 'post',
                    dataType: 'json',
                    data: function (params) {
                        params._token = token ? token : $('input[name="_token"]').val();
                        return params;
                    },
                },
                width: width ? width : 500,
                language: select2_lang,
            });
        });
        if ($('#page-overlay').size()) {
            var submmited = false;
            $('form').each(function (e) {
                submit = this.onsubmit;
                this.onsubmit = function () {
                    if (!submmited) {
                        submmited = true;
                        res = !submit || (submit && submit(e));
                        if (!submit || res) {
                            $.pageOverlay.show();
                        }
                        return res;
                    } else {
                        return false;
                    }
                }
            });
        }
        $(window).resize();
    });
});
