$(function () {
    $(document).ready(function () {
        if ($('#support-search-div').size()) {
            $('#select-user').change(function () {
                $.pageOverlay.show();
                location.href =  '/support/user/' + this.value;
                $('#user-form').hide();
            });
            $('#select-entity').change(function () {
                $.pageOverlay.show();
                location.href =  '/support/entity/' + this.value;
                $('#user-form').hide();
            });
        }
    });
});
