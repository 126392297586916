$(function () {
    $(document).ready(function () {
        if ($('[id^="email-list"]:eq(0)').size()) {
            var list = $('[id^="email-list"]:eq(0)');
            var addEmail;
            var removeEmail;
            var limit = 5;
            var parent = list.parent();
            parent.find('div[id^="email-list-"]').children().remove();
            var updateButtons = function () {
                size = list.children().size();
                if (size == 1) {
                    list.find('.email-add').removeClass('hide');
                    list.find('.email-remove').addClass('hide');
                } else {
                    list.find('.email-add').addClass('hide');
                    if (size < limit) {
                        list.find('.email-add:last').removeClass('hide');
                    }
                    list.find('.email-remove').removeClass('hide');
                }
            };
            var updateIndexes = function () {
                list.children().each(function (index) {
                    $(this).find('input[name^="email_name["]').attr('name', 'email_name[' + index + ']').attr('id','input-email-name-' + index);
                    $(this).find('input[name^="email["]').attr('name', 'email[' + index + ']').attr('id','input-email-' + index);
                })
            };
            addEmail = function (e,email,email_name,errorName,errorEmail) {
                if (list.children().size() < limit) {
                    model=parent.find('div[id^="email-model-"]').clone(true).removeClass('hide').attr('id',null);
                    e=model.find('input[name^="email["]').attr('disabled',null);
                    if (undefined !== email) {
                        e.val(email);
                    }
                    n=model.find('input[name^="email_name["]').attr('disabled',null);
                    if (undefined !== email_name) {
                        n.val(email_name);
                    }
                    model.find('.email-add').unbind('click').click(addEmail);
                    model.find('.email-remove').unbind('click').click(removeEmail);
                    list.append(model);
                    updateIndexes();
                    if (errorName) {
                        e.parent().attr('id','div-' + e.attr('id')).addClass('has-error');
                        e.find('~span').attr('id','error-' + e.attr('id')).html(errorName);
                    }
                    if (errorEmail) {
                        n.parent().attr('id','div-' + e.attr('id')).addClass('has-error');
                        n.find('~span').attr('id','error-' + e.attr('id')).html(errorEmail);
                    }
                    updateButtons();
                    model.cleanErrors();
                }
            };
            removeEmail = function (e) {
                if (list.children().size() < 2) {
                    return false;
                }
                else {
                    $(this).parent().parent().remove();
                    updateIndexes();
                    updateButtons();
                }
            };
            if (!parent.find('div[id^="email-list-"]').children().size()) {
                var addEm = $('input.email-add');
                var addNam = $('input.email-name-add');
                if (addEm.size()) {
                    addEm.each(function (index) {
                        addEmail(
                            null,
                            this.value,
                            addNam[index].value,
                            $(addEm[index]).attr('data-error'),
                            $(addNam[index]).attr('data-error')
                        );
                    });
                } else {
                    addEmail();
                }
            }
        }
    })
});
