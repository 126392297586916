$(function () {
    $(document).ready(function () {
        var table_requests;
        favorites = (parseInt($('#favorites').val()) == 1);
        if ($('#requests-tab').size() || $('#erequests-tab').size() || $('#quotes-tab').size()) {
            $('#select-dashboard-entity').change(function () {
                if (this.value) {
                    $('#entity-logo').hide();
                    $.href('/entity/' + this.value + '/dashboard');
                }
            });
            var langStrings = {};
            var unfav = '<span class="favorite-request glyphicon glyphicon-star-empty text-gray font-medium" />';
            var fav = '<span class="favorite-request glyphicon glyphicon-star text-yellow font-medium" />';
            var favChange = function (star, set, click) {
                newicon = $(set ? fav : unfav);
                if (click) {
                    newicon.click(favClick).addClass('clickable');
                }
                star.parent().append(newicon);
                star.remove();
            };
            var favClick = function () {
                var set = $(this).hasClass('glyphicon-star-empty') ? 1 : 0;
                var eid = $('#entity-id').val();
                var qrid = $(this).parents('tr:eq(0)').find('input[name="quote_request[]"]').val();
                var star = $(this);
                $.ajax({
                    method: 'POST',
                    url: '/entity/' + eid + '/request/' + qrid + '/favorite',
                    data: {
                        favorite: set ? 1 : 0,
                        _token: $('input[name="_token"]').val(),
                    },
                    success: function () {
                        favChange(star, set, window.favorite);
                    },
                });
            };
            var favorite = function (table) {
                table.find('td.favorite').each(function () {
                    var favorite = $(this).find('>span');
                    var val = favorite.html() ? favorite.html().trim() : '';
                    if (val.length == 1) {
                        if (val == '1') {
                            favChange(favorite, 1, window.favorite);
                        } else {
                            favChange(favorite, 0, window.favorite);
                        }
                    }
                });
            };
            var status = function (table) {
                table.find('td.status').each(function () {
                    quotes = $(this).find('span.icon-quotes');
                    quotes = $(this).find('span.icon-quotes');
                    quote_count = parseInt(quotes.html());
                    msgs = $(this).find('span.icon-messages');

                    if (table.attr('id').indexOf('erequests') > 0) {
                        severity = !quote_count ? 'danger' : 'success';
                        wrap = $('<div>').addClass('mybadge');
                        wrap.attr('data-toggle',quotes.attr('data-toggle'))
                            .attr('data-placement',quotes.attr('data-placement'))
                            .attr('data-title',quotes.attr('data-title'));
                        icon = $('<i class="fa fa-file-text text-muted">')
                        badge = $('<span class="label label-' + severity  + ' mybadge">').html(quotes.html());
                        quotes.wrap(wrap);
                        quotes.before(icon);
                        quotes.before(badge);
                        quotes.remove()
                    } else {
                        if (quote_count) {
                            wrap = $('<div>').addClass('mybadge');
                            wrap.attr('data-toggle',quotes.attr('data-toggle'))
                                .attr('data-placement',quotes.attr('data-placement'))
                                .attr('data-title',quotes.attr('data-title'));
                            icon = $('<i class="fa fa-file-text text-muted">').addClass(quotes.attr('data-class'));
                            quotes.wrap(wrap);
                            quotes.before(icon);
                            wrap.tooltip();
                        }
                        quotes.remove();
                    }

                    if (msgs.size()) {
                        msgs_count = parseInt(msgs.html());
                        severity = msgs_count ? 'danger' : 'success';
                        wrap = $('<div>').addClass('mybadge');
                        wrap.attr('data-toggle', msgs.attr('data-toggle'))
                            .attr('data-placement', msgs.attr('data-placement'))
                            .attr('data-title', msgs.attr('data-title'));
                        icon = $('<i class="fa fa-envelope text-muted">');
                        badge = $('<span class="label label-' + severity + ' mybadge">').html(msgs.html());
                        msgs.wrap(wrap);
                        msgs.before(icon);
                        msgs.before(badge);
                        msgs.remove()
                        wrap.tooltip();
                    }
                });
            };
            var c = 0;
            var ld = false;
            var showData = function (id, servicename) {
                var table = $('#entity-' + servicename +'-table-' + id);
                table.on('preXhr.dt', function () {
                    if (!ld) $.pageOverlay.show();
                });
                table.on('xhr.dt', function () {
                    $.pageOverlay.hide();
                    if (!$.pageOverlay.count()) ld = true;
                });
                table.on('draw.dt', function () {
                    $(this).find('td.actions').each(function () {
                        data = $(this).clone();
                        actions = data.html().split('|');
                        $(this).html('');
                        for(p = 0; p < actions.length; p++) {
                            var info = actions[p].split(',');
                            if (info.length > 1) {
                                type = info[0];
                                action = info[1];
                                target = info.length > 2 ? info[2] : false;
                                if (type != 'destroy') {
                                    var btn = eval("$('#" + servicename + "-' + type).clone().attr('id', null).click(function () {"
                                        + (target ? "w=window.open('" + action + "', '" + target + "');w.focus();" : "$.href('" + action + "');")
                                        + "});");
                                } else {
                                    var btn = eval("$('#" + servicename + "-' + type).clone().attr('id', null)");
                                    btn.click(function (e) {
                                        var button = $(this);
                                        var form = $('<form method="post">').append($('<input type="hidden" name="_method" value="delete">')).append($('input[name="_token"]:eq(0)')).attr('action',action);
                                        $('#modal-default').ask(
                                            'danger',
                                            $(this).attr('data-question'),
                                            $(this).attr('data-title'),
                                            $(this).attr('data-confirm'),
                                            $(this).attr('data-cancel'),
                                            function () {
                                                button.after(form);
                                                button.parent().find('form')[0].submit();
                                            }
                                        );
                                    });
                                }
                                $(this).append(p > 0 ? '&nbsp;' : '').append(btn);
                            }
                        }
                    });
                    favorite(table);
                    status(table);
                    entity_info(table);
                });
                var init = function () {
                    $('#' + this.attr('id') + '_length').find('select').select2({minimumResultsForSearch: Infinity});
                    this.api().columns().every(function () {
                        var column = this;
                        if (!$(column.header()).hasClass('dt-nosearch')) {
                            var select = $('<select class="form-control select2"><option value="">' + $(column.header()).html() + '</option></select>')
                                .appendTo($(column.header()).empty())
                                .addClass('select2-initilized').css('width','99%').select2({minimumResultsForSearch: Infinity})
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex(
                                        $(this).val()
                                    );
                                    column
                                        .search(val ? '^' + val + '$' : '', true, false)
                                        .draw();
                                });
                            column.data().unique().sort().each(function (d) {
                                select.append('<option value="' + d + '">' + d + '</option>')
                            });
                        }
                    });
                    if ($('.select2:not(.select2-initilized), select:not(.select2)').size()) {
                        this.api().columns().every(function () {
                            $(this.header()).addClass('vertical-align-middle-header').find('.select2-selection').on(
                                'click',
                                function (event) {
                                    event.stopPropagation();
                                }
                            );
                        });
                    }
                    $('.datatable-header-addon').each(function () {
                        wrapper = $(this).removeClass('hide').parents('.tab-pane:eq(0)').find('.dataTables_wrapper');
                        label = wrapper.find('.dataTables_filter>label');
                        if (!label.size()) {
                            label = $('<label>');
                            divf = $('<div>').addClass('dataTables_filter').append(label);
                            wrapper.find('>div.row>div:eq(1)').append(divf);
                        }
                        label.append($(this).parent());
                    });
                };
                sort = $('#can-sort').val() == 1;
                filtered = $('#can-filter').val() == 1;
                config = {
                    language: langStrings,
                    autoWidth: false,
                    serverSide: true,
                    ajax: {
                        url: '/entity/' + id + '/dashboard/' + servicename,
                        method: 'get',
                        data: {_token: $('input[name="_token"]').val()}
                    },
                    scrollX: false,
                    responsive: true,
                    searching: true,
                    ordering: true,
                    initComplete: init,
                };
                if (servicename == 'erequests') {
                    config.columns = [
                        {orderable: true, width: '28%', className: 'text-left'},
                        {orderable: true, width: '10%', className: 'text-center'},
                        {orderable: true, width: '10%', className: 'text-center'},
                        {orderable: true, width: '10%', className: 'text-center'},
                        {orderable: true, width: '15%'},
                        {orderable: false, width: '10', className: 'status text-center'},
                        {orderable: false, width: '15', className: 'actions text-center'},
                    ];
                    table.DataTable(config);
                } else if (servicename == 'requests') {
                    table_requests = table;
                    config.searching = filtered;
                    config.ordering = sort;
                    if(favorites) {
                        config.columns = [
                            {orderable: true, width: '2%', className: 'favorite text-center'},
                            {orderable: true, width: '20%'},
                            {orderable: true, width: '20%', className: 'text-left'},
                            {orderable: sort, width: '11%', className: 'text-center'},
                            {orderable: true, width: '11%', className: 'text-center'},
                            {orderable: sort, width: '10%', className: 'text-center'},
                            {orderable: false, width: '10', className: 'status text-center'},
                            {orderable: false, width: '10%', className: 'actions text-center'},
                        ];
                    } else {
                        config.columns = [
                            {orderable: true, width: '20%'},
                            {orderable: true, width: '20%', className: 'text-left'},
                            {orderable: sort, width: '11%', className: 'text-center'},
                            {orderable: true, width: '11%', className: 'text-center'},
                            {orderable: sort, width: '10%', className: 'text-center'},
                            {orderable: false, width: '10', className: 'status text-center'},
                            {orderable: false, width: '10%', className: 'actions text-center'},
                        ];
                    }
                    if (table_requests) {
                        table_requests = table.DataTable(config);
                    } else {
                        table.DataTable(config);
                    }
                } else {
                    config.columns = [
                        {orderable: true, width: '20%'},
                        {orderable: true, width: '20%'},
                        {orderable: true, width: '20%', className: 'text-left'},
                        {orderable: true, width: '11%', className: 'text-center'},
                        {orderable: false, width: '10', className: 'status text-center'},
                        {orderable: false, width: '10%', className: 'actions text-center'},
                    ];
                    table.DataTable(config);
                }
            };
            var datatables = $(document).find('[id^="entity-requests-table-"],[id^="entity-erequests-table-"],[id^="entity-quotes-table-"]');
            if (datatables.size()) {
                $.ajax({
                    url: '/plugins/datatables/plugins/i18n/Portuguese-Brasil.lang',
                    success: function (lang) {
                        eval('langStrings = ' + lang);
                        datatables.each(function () {
                            var id = this.id.split('-')[3];
                            var servicename = this.id.split('-')[1];
                            showData(id, servicename);
                        });
                    }
                });
            }
            bfilter = $('#btn-filter');
            if(bfilter.size()) {
                bfilter.click(function () {
                    $.ajax({
                        method: 'post',
                        url: '/entity/' + $('#entity-id').val() + '/dashboard/filter',
                        data: {
                            '_token': $('input[name="_token"]').val()
                        },
                        success: function (res) {
                            if (res.filtered) {
                                bfilter.removeClass('btn-default').addClass('btn-primary');
                            } else {
                                bfilter.removeClass('btn-primary').addClass('btn-default');
                            }
                            table_requests.ajax.reload();
                        }
                    });
                })
            }
        }
    })
});
