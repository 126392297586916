$(function () {
    $(document).ready(function () {
        var lastcep=$('#input-address-postal-code').val();
        if ($('[id^="form-entity-address-"]').size() || $('[id^="form-entity-create-"]').size()) {
            var complete = function (event) {
                target = event.currentTarget ? event.currentTarget : event.srcElement;
                target = target ? target : this;
                var cep = $(target).val().trim();
                var street = $('#input-address-street');
                var neighborhood = $('#input-address-neighborhood');
                var city = $('#input-address-city');
                var state = $('#select-address-state');
                var ibge = $('#input-address-ibge');
                $('#div-input-address-postal-code').removeClass('has-error');
                $('#error-input-address-postal-code').html('');
                if (
                    (
                        cep.length == 9
                        && cep.indexOf('_') == -1
                    )
                ) {
                    if (
                        !street.val().trim()
                        || !neighborhood.val().trim()
                        || !city.val().trim()
                        || !state.val().trim()
                        || lastcep != cep
                    ) {
                        data = {
                            cep: cep,
                            _token: $(document).find('input[name="_token"]').val()
                        };
                        $('#address-info').removeClass('hide');
                        $('#address-info-overlay').removeClass('hide');
                        $.ajax({
                            url: '/cep/consulta',
                            method: 'post',
                            data: data,
                            success: function (data) {
                                ibge.val('');
                                if (data.status) {
                                    lastcep = $('#input-address-postal-code').val();
                                    street = $('#input-address-street');
                                    neighborhood = $('#input-address-neighborhood');
                                    city = $('#input-address-city');
                                    state = $('#select-address-state');
                                    ibge = $('#input-address-ibge');
                                    street.val(data.logradouro);
                                    street.change()
                                    neighborhood.val(data.bairro);
                                    neighborhood.change();
                                    city.val(data.localidade);
                                    city.change();
                                    state.val(data.uf);
                                    state.change();
                                    ibge.val(data.ibge);
                                    ibge.change();
                                }
                                $('#address-info-overlay').addClass('hide');
                            },
                            error:function (data) {
                                $('#address-info-overlay').addClass('hide');
                                $('#address-info').addClass('show');
                                $('#div-input-address-postal-code').addClass('has-error');
                                $('#error-input-address-postal-code').html('Problems found searching for postal code!');
                            }
                        });
                    } else if ($('#address-info').hasClass('hide')) {
                        $('#address-info-overlay').addClass('hide');
                        $('#address-info').removeClass('hide');
                    }
                } else  {
                    $('#address-info').addClass('hide');
                    //$('#address-info-overlay').removeClass('hide');
                }
            };
            $('#input-address-postal-code').on('change, blur, keyup', function (event) {complete(event,true);}).inputmask('remove').inputmask('99999-999');
            complete({currentTarget:'#input-address-postal-code',type:'focus'});
        }
    });
});
