$(function () {
    $(document).ready(function () {
        window.search = null;
        if ($('#btn-product-search').size()) {
            var sp = $('#input-search-product');
            var pid = $('#product-id').val();
            var results = $('#select-search-results');
            var searchstr = $('#input-search-product');
            var clear_results = function () {
                $('#input-search-product').attr('disabled', null);
                $('#btn-product-search').attr('disabled', null);
                $('#btn-product-create').attr('disabled', null);
                $('#select-search-results').attr('disabled', null);
                $('#select-item-unit-code').attr('disabled', null);
                $('#input-item-category-id').attr('disabled', null);
                $('#select-search-results').children().remove();
                $('#div-clear').addClass('hide');
                $('#div-new-product-code').addClass('hide');
                $('#div-description').addClass('hide');
                $('#div-unit-quantity').addClass('hide');
                $('#div-notes').addClass('hide');
                $('#div-address').addClass('hide');
                $('#div-category').addClass('hide');
                $('#item-save-button').addClass('hide');
                $('#btn-product-create').removeClass('hide');
            };
            var select_result = function () {
                selected = $(results[0].options[results[0].options.selectedIndex]);
                text = selected.html();
                value = selected.val();
                $('#input-search-product').attr('disabled', null);
                $('#btn-product-search').attr('disabled', null);
                $('#btn-product-create').attr('disabled', null);
                $('#select-search-results').attr('disabled', null);
                $('#text-item-description').val(text);
                $('#text-item-description').attr('disabled', 'disabled');
                $('#item-item-unit-code').attr('disabled', 'disabled');
                $('#select-item-unit-code').attr('disabled', 'disabled');
                $('#input-item-category-id').attr('disabled', 'disabled');
                $('#div-select-item-unit-code').addClass('hide');
                $('#div-input-item-quantity').removeClass('hide');
                $('#div-clear').removeClass('hide');
                $('#div-new-product-code').addClass('hide');
                $('#div-description').removeClass('hide');
                $('#div-unit-quantity').removeClass('hide');
                $('#div-notes').removeClass('hide');
                $('#div-address').removeClass('hide');
                $('#div-category').addClass('hide');
                $('#item-save-button').removeClass('hide');
                $('#btn-product-create').addClass('hide');
            }
            var new_product = function () {
                $('#input-search-product').attr('disabled', 'disabled');
                $('#select-search-results').attr('disabled', 'disabled');
                $('#text-item-description').attr('disabled', null);
                $('#item-item-unit-code').attr('disabled', null);
                $('#select-item-unit-code').attr('disabled', null);
                $('#input-item-category-id').attr('disabled', null);
                $('#div-select-item-unit-code').removeClass('hide');
                $('#div-input-item-quantity').removeClass('hide');
                $('#div-clear').removeClass('hide');
                $('#div-new-product-code').removeClass('hide');
                $('#div-description').removeClass('hide');
                $('#div-unit-quantity').removeClass('hide');
                $('#div-notes').removeClass('hide');
                $('#div-address').removeClass('hide');
                $('#div-category').removeClass('hide');
                $('#item-save-button').removeClass('hide');
                $('#div-description').removeClass('hide');
                $('#btn-product-create').addClass('hide');
                $('#text-item-description').val('');
                $('#div-select-search-results').removeClass('had-error');
                $('#err-select-search-results').addClass('hide');
            };
            $('#btn-product-search').click(function () {
                if (window.search) {
                    window.search.abort();
                }
                if (searchstr.length > 0) {
                    var codes = [];
                    $('[name="special_code[]"]').each(function () {
                        codes.push($(this).val());
                    });
                    window.search = $.ajax({
                        method: 'post',
                        url: '/product/search',
                        data: {search: searchstr.val().trim().toUpperCase(), codes: codes, _token: $(document).find('[name="_token"]').val()},
                        success: function (data) {
                            clear_results();
                            if (data.status) {
                                results = $('#select-search-results');
                                sel = -1;
                                for (i = 0; i < data.results.length; i++) {
                                    o = $('<option>').val(data.results[i].id).append(
                                        data.results[i].code + ' - ' + data.results[i].desc + ' - ' + data.results[i].unit
                                    ).attr('title', data.results[i].desc).attr('data-unit', data.results[i].unit).attr('data-code', data.results[i].code);
                                   results.append(o);
                                   if (pid && pid == data.results[i].id) {
                                       sel = i;
                                   }
                                }
                            } else {
                                $('#modal-default').ask(data.severity, data.message, data.title, data.ok, data.dismiss, function () {
                                    new_product();
                                });
                            }
                            if (sel > -1) {
                                results[0].selectedIndex = sel;
                                results.change();
                            }
                        }
                    });
                } else {
                    results.find('option').remove();
                }
                results.change(function () {
                    if (this.value) {
                        select_result();
                    }
                });
            });
            $('#btn-product-create').click(function () {
                new_product();
            })
            $('#btn-clear').click(function () {
                clear_results();
                $('.has-error').removeClass('has-error');
                $('#div-select-search-results').removeClass('had-error');
                $('#err-select-search-results').addClass('hide');
            });
            //sp.trigger('keyup');
            if (pid) {
                $('#btn-product-search').trigger('click');
            } else if ($('.has-error').size()) {
                new_product();
            }
        }
    });
});
