var entity_info = function (ref) {
    ref = ref ? ref : $(document);
    if (ref.find('[id^="entity-info-id-"]').size()) {
        modal = $('#entity-info-modal');
        ref.find('[id^="entity-info-id-"]').each(function () {
            $(this).click(function () {
                temp = this.id.split('-');
                id = temp[3];
                type = undefined != temp[4] ? temp[4] : '';
                userid = undefined != temp[5] ? temp[5] : '';
                $.pageOverlay.show();
                $.ajax({
                    url: '/entity/' + id + '/info' + (userid ? ('/' + userid) : ''),
                    method: 'get',
                    success: function (info) {
                        $.pageOverlay.hide();
                        label = $('#entity_info_label').clone(true).attr('id', null);
                        value = $('#entity_info_value').clone(true).attr('id', null);
                        info_data = $('#entity_info_data');
                        info_data.html('');
                        for(i = 0; i < info.data.length; i++) {
                            l = label.clone().html(info.data[i][0]);
                            v = value.clone().html(info.data[i][1]);
                            info_data.append($('<div>').append(l).append(' ').append(v));
                        }
                        if (info.addresses.length) {
                            for(i = 0; i < info.addresses.length; i++) {
                                address = info.addresses[i];
                                l = label.clone().html(address.address[0]);
                                v = value.clone().html(address.address[1]);
                                info_data.append($('<div>').append(l).append(' ').append(v));
                                if (address.telephones.length) {
                                    for(j = 0; j < address.telephones.length; j++) {
                                        tel = address.telephones[j][0];
                                        ext = address.telephones[j][1];
                                        l = label.clone().html(tel[0]);
                                        v = value.clone().html(tel[1]);
                                        if (ext[1]) {
                                            ext = ' ' + ext[0] + ' ' + ext[1];
                                        } else {
                                            ext = '';
                                        }
                                        info_data.append($('<div>').append(l).append(' ').append(v).append(ext));
                                    }
                                }
                            }
                        }
                        if (info.contact.length) {
                            l = label.clone().html(info.contact[0]);
                            v = value.clone().html(info.contact[1]);
                            info_data.append($('<div>').append(l).append(' ').append(v));
                        }
                        $('#entity_info_title').html($('#entity_info_header_' + type).val());
                        modal.modal('show');
                    },
                    error:function (data) {
                        $.pageOverlay.hide();
                        console.log(id + ' ajax error');
                    }
                });
            });
        });
    }
}
$(function () {
    /*Initialization*/
    $(document).ready(function () {
        if ($('#modal-entity-request').size()) {
            var m=$('#modal-entity-request');
            var v=m.find('#input-identifier');
            var f=$('#form-request');
            var submit=false;
            f.submit(function(){return false;});
            var btnCancel = function () {
                $('#entity-data').addClass('hide');
                $('#entity-request-action').addClass('hide');
                $('#entity-request-search').removeClass('hide');
                $('#entity-request-action').addClass('hide');
                $('#create-entity-action').addClass('hide');
                $('#error-input-identifier').addClass('hide');
                $('#entity-not-found').addClass('hide');
                $('#div-input-identifier').removeClass('has-error');
                v.attr("disabled",null);
                m.adjustModalMaxHeightAndPosition();
            };
            var accessEntity = function(e,identifier) {
                btnCancel();
                m.find('#btn-entity-send-cancel').click(btnCancel);
                m.find('#btn-entity-create-cancel').click(btnCancel);
                m.find('#btn-entity-create-modal').click(function () {
                    btnCancel();
                    var url = '/entity/create';
                    var form = $('<form method="post">').append($('<input type="hidden" name="identifier" value="' + v.val() + '">')).append($('input[name="_token"]:eq(0)')).attr('action',url);
                    $(this).after(form);
                    form.submit();
                });

                $('#div-input-identifier').removeClass('has-error');
                ms=m.find('#textarea-message').val('');
                v.attr("disabled",null);
                m.modal('show');
                if (identifier) {
                    v.val(identifier);
                    $('#btn-entity-search').click();
                }
            };
            m.find('#btn-entity-send').click(function () {
                m.modal('hide');
                f.unbind();
                f.find('input').attr('disabled',null);
                f.submit();
            });
            m.find('#btn-entity-search').click(function () {
                l=v.val().length;
                data = $('#form-request').serialize();
                if (l==14 || l==18) {
                    /*Search*/
                    $.ajax({
                        url: '/entity/check',
                        method: 'post',
                        data: data,
                        success: function (data) {
                           if (data.status == 1) {
                               $('#entity-data').removeClass('hide');
                               $('#entity-name').html(data.data.name);
                               $('#entity-request-search').addClass('hide');
                               $('#entity-request-action').removeClass('hide');
                               $('#create-entity-action').addClass('hide');
                               $('#entity-not-found').addClass('hide');
                               $('#div-input-identifier').removeClass('has-error');
                               v.attr("disabled","disabled");
                               m.adjustModalMaxHeightAndPosition();
                           }  else {
                               $('#div-input-identifier').addClass('has-error');
                               $('#entity-request-action').addClass('hide');
                               if (data.status > 0) {
                                   $('#error-input-identifier').removeClass('hide');
                                   $('#error-input-identifier').html(data.message);
                               } else {
                                   $('#entity-request-search').addClass('hide');
                                   $('#create-entity-action').removeClass('hide');
                                   $('#entity-not-found').removeClass('hide');
                                   v.attr("disabled","disabled");
                               }
                               m.adjustModalMaxHeightAndPosition();
                           }
                        },
                        error:function (data) {
                            $('#div-input-identifier').removeClass('has-error');
                        }
                    });
                } else {
                    $('#div-input-identifier').addClass('has-error');
                }
            });
            if ($('#modal-entity-request').hasClass('no-entities') && $('#no-entities').size()) {
                accessEntity();
            }
            $('.btn-entity-access').click(accessEntity);
        }
        reset = function () {
            $('#form-cpf').addClass('hide').find('input').attr('disabled', 'disabled');
            $('#form-cnpj').addClass('hide').find('input').attr('disabled', 'disabled');
            $('#div-logo').addClass('hide');
            $('#form-entity-buttons').addClass('hide');
            $('#form-common').addClass('hide');
            $('#address-tab').addClass('hide');
            $('#save-button').addClass('hide');
            $('#input-identifier-type').val('');
            $('#type-cpf').addClass('hide');
            $('#type-cnpj').addClass('hide');
            $('#input-identifier-error-cpf').addClass('hide');
            $('#input-identifier-error-cnpj').addClass('hide');
            $('#input-identifier-div').removeClass('has-error');
            $('#input-identifier-error-cpf-exists').addClass('hide');
            $('#input-identifier-error-cnpj-exists').addClass('hide');
        }
        ecreate = $('#form-entity-create').size();
        eedit = $('#form-entity-edit').size();
        tipo = ecreate ? 'create' : 'edit';
        if (ecreate || eedit) {
            var checkType = function (e) {
                target = e && (e.currentTarget) ? e.currentTarget : (e && e.srcElement ? e.srcElement : null);
                id = target ? target.id : null;
                b = $('#type-buyer');
                s = $('#type-seller');
                buyer = b.is(':checked');
                seller = s.is(':checked');
                open_quotes = $('#open_quotes').val();
                open_quote_requests = $('#open_quote_requests').val();
                if (id == 'type-seller' && !seller && open_quotes > 0) {
                    $('#modal-default').danger(
                        $('#open_quotes').attr('data-message'),
                        $('#open_quotes').attr('data-title'),
                        $('#open_quotes').attr('data-dismiss'),
                        'void()'
                    );
                    setTimeout(function () {
                        s.iCheck('check');
                    }, 10);
                    return false;
                }
                if (id == 'type-buyer' && !buyer && open_quote_requests > 0) {
                    $('#modal-default').danger(
                        $('#open_quote_requests').attr('data-message'),
                        $('#open_quote_requests').attr('data-title'),
                        $('#open_quote_requests').attr('data-dismiss'),
                        'void()'
                    );
                    setTimeout(function () {
                        b.iCheck('check');
                    }, 10);
                    return false;
                }
                if (!buyer && !seller) {
                    setTimeout(function () {
                        b.iCheck('check');
                    }, 10);
                }
                if (!$('#form-common').is(':visible')) {
                    if (
                        location.hash == '#addresses-tab'
                        || location.hash == '#users-tab'
                        || location.hash == '#options-tab'
                        || location.hash == '#categories-tab'
                        || location.hash == '#subscription-tab'
                    ) {
                        location.href = '#general-tab';
                        $('#general-tab').tab('show');
                    }
                    $('#options-tab').parent().hide();
                    $('#categories-tab').parent().hide();
                    $('#subscription-tab').parent().hide();
                } else {
                    $('#subscription-tab').parent().show();
                    if (buyer) {
                        $('#options-tab').parent().show();
                        $('#subscription-info').show();
                    } else {
                        $('#options-tab').parent().hide();
                        $('#subscription-info').hide();
                    }
                    if (seller) {
                        $('#categories-tab').parent().show();
                        $('#subscription-table').show();
                        $('#subscription-info').hide();
                    } else {
                        $('#categories-tab').parent().hide();
                        $('#subscription-table').hide();
                        $('#subscription-info').show();
                    }
                }

            };
            checkExist = function () {
                var i = this != window ? $(this) : $('#input-identifier');
                var s = i.val().replace(/\/|\.|-/gi,'');
                data = $('#form-entity-create').serialize();
                if (s.length == 11 || s.length == 14) {
                    check = function (action) {
                        $.ajax({
                            url: '/entity/check',
                            method: 'post',
                            data: data,
                            success: function (data) {
                                if (0 != data.status) {
                                    reset();
                                    $('#input-identifier-div').addClass('has-error');
                                    $('#input-identifier-error-cpf').addClass('hide');
                                    $('#input-identifier-error-cnpj').addClass('hide');
                                    $('#input-identifier-error-' + (s.length == 11 ? 'cpf' : 'cnpj') + '-exists').removeClass('hide');
                                    $('#input-identifier-error-' + (s.length == 11 ? 'cnpj' : 'cph') + '-exists').addClass('hide');
                                    if (!$('#modal-default').is(':visible') && 1 == data.status) {
                                        $('#modal-default').ask('warning', data.message, data.title, data.confirm, data.dismiss, function(){
                                            $('#modal-default').modal('hide');
                                            setTimeout(function () { accessEntity(null, i.val()); },100);
                                        });
                                    }
                                } else {
                                    action();
                                }
                            },
                            error:function (data) {
                                $('#modal-default').danger('Error sending data. Try again later.' + data.responseText, 'Error', 'Close');
                            }
                        });
                    }
                    if (s.length == 11) {
                        $('#input-identifier-error-cnpj').addClass('hide');
                        if (CPFCNPJ.validaCPF(s)) {
                            check(function () {
                                $('#form-cnpj').addClass('hide').find('input').attr('disabled', 'disabled');
                                $('#form-cpf').removeClass('hide').find('input').attr('disabled', null);
                                $('#form-common').removeClass('hide');
                                $('#address-tab').removeClass('hide');
                                $('.logo-default').attr('src', '/img/' + (window.entity ? 'logo.jpg' : 'avatar.png'));
                                $('#div-logo').removeClass('hide');
                                $('#form-entity-buttons').removeClass('hide');
                                $('#save-button').removeClass('hide');
                                $('#type-cpf').removeClass('hide');
                                $('#type-cnpj').addClass('hide');
                                $('#input-identifier-type').val('cpf');
                                $('#input-identifier-div').removeClass('has-error');
                                $('#input-identifier-error-cpf').addClass('hide');
                                $('#input-identifier-error-cpf-exists').addClass('hide');
                                $('#input-identifier-error-cnpj-exists').addClass('hide');
                                checkType();
                            });
                        } else {
                            $('#input-identifier-div').addClass('has-error');
                            $('#input-identifier-error-cpf').removeClass('hide');
                            $('#input-identifier-error-cpf-exists').addClass('hide');
                            $('#input-identifier-error-cnpj-exists').addClass('hide');
                        }
                    } else if (s.length == 14) {
                        $('#input-identifier-error-cpf').addClass('hide');
                        if (CPFCNPJ.validaCNPJ(s)) {
                            check(function () {
                                $('#form-cpf').addClass('hide').find('input').attr('disabled', 'disabled');
                                $('#form-cnpj').removeClass('hide').find('input').attr('disabled', null);
                                $('#form-common').removeClass('hide');
                                $('#address-tab').removeClass('hide');
                                $('.logo-default').attr('src', '/img/logo.jpg');
                                $('#div-logo').removeClass('hide');
                                $('#form-entity-buttons').removeClass('hide');
                                $('#save-button').removeClass('hide');
                                $('#type-cpf').addClass('hide');
                                $('#type-cnpj').removeClass('hide');
                                $('#input-identifier-type').val('cnpj');
                                $('#input-identifier-div').removeClass('has-error');
                                $('#input-identifier-error-cnpj').addClass('hide');
                                $('#input-identifier-error-cpf-exists').addClass('hide');
                                $('#input-identifier-error-cnpj-exists').addClass('hide');
                                checkType();
                            });
                        } else {
                            $('#input-identifier-div').addClass('has-error');
                            $('#input-identifier-error-cnpj').removeClass('hide');
                            $('#input-identifier-error-cpf-exists').addClass('hide');
                            $('#input-identifier-error-cnpj-exists').addClass('hide');
                        }
                    }
                } else {
                    reset();
                }
                checkType();
            }
            if ($('#input-identifier').attr('type') == 'text') {
                $('#input-identifier').keyup(checkExist);
                $('#input-identifier').on('input',checkExist);
                $('#input-identifier').change(checkExist);
            } else {
                $('#form-cpf:not(.hide)').find('input').attr('disabled', null);
                $('#form-cnpj:not(.hide)').find('input').attr('disabled', null);
            }
            $('#type-buyer').on('ifToggled click keypress', checkType);
            $('#type-seller').on('ifToggled click keypress', checkType);
            $('#input-identifier').trigger('keyup');
            if (eedit) {
                checkType();
            }
        }
        /* Entity Options rules */
        if ($('#entity-options').size()) {
            var checkOptions = function (event, state) {
                if (!state) {
                    $('#input-show-seller-questions').bootstrapSwitch('state', false);
                }
                $('#input-show-seller-questions').bootstrapSwitch('disabled', !state, false);
            }
            $('#input-seller-questions').on('switchChange.bootstrapSwitch', checkOptions);
            checkOptions(null, $('#input-seller-questions').bootstrapSwitch('state'));
        }
        /* Request Message */
        if ($('.button-access-request-message').size()) {
            $('.button-access-request-message').click(function () {
                modal = $('#modal-default');
                modal.info(
                    $(this).find('.access-request-message').html(),
                    $(this).attr('data-title'),
                    $(this).attr('data-dismiss'),
                    function () {}
                )
                modal.modal('show');
            });
        }
        /* Accept/Deny/Decline */
        var doAction = function (target, type) {
            var eid = target.id.split('-')[2];
            var button = $(target);
            var url = '/entity/' + target.id.split('-').slice(2,5).join('/');
            var form = $('<form method="post">').append($('<input type="hidden" name="action" value="' + type + '">')).append($('input[name="_token"]:eq(0)')).attr('action',url);
            severity = type == 'accept' ? 'success' : 'warning';
            $('#modal-default').ask(
                severity,
                $(target).attr('data-question'),
                $(target).attr('data-title'),
                $(target).attr('data-confirm'),
                $(target).attr('data-cancel'),
                function () {
                    button.after(form);
                    button.parent().find('form')[0].submit();
                }
            );
        };
        $('.btn-accept-resource').each(function (e) {
            $(this).click(function (e) {
                doAction(this,'accept');
            });
        });
        $('.btn-deny-resource').each(function (e) {
            $(this).click(function (e) {
                doAction(this,'deny');
            });
        });
        $('.btn-decline-resource').each(function (e) {
            $(this).click(function (e) {
                doAction(this,'decline');
            });
        });
        /* Dashboard */
        $('.btn-entity-dashboard').each(function () {
            $(this).click(function () {
                $.href('/' + this.id.split('-').slice(1).join('/'));
            });
        });
        var forms=$('#form-entity-quote-request-edit, #form-entity-quote-request-create, #form-entity-quote-edit, #form-entity-quote-create');
        if (forms.size()) {
            split = forms[0].id.split('-');
            last = split[split.length - 1];
            owner = split[split.length - 2] == 'request' ? 'quote_request' : 'quote';
            if (last == 'create') {
                forms.find('input, select, textarea').each(function () {
                    if ($(this).attr('name')) {
                        $(this).on('daterange.clear daterange.change change summernote.blur', function () {
                            $.ajax({
                                method: 'post',
                                url: '/flash',
                                data: {
                                    id: owner + '_' + forms.find('input[name="entity_id"]').val(),
                                    name: $(this).attr('name'),
                                    value: $(this).val(),
                                    _token: forms.find('input[name="_token"]').val(),
                                    success: function () {
                                        $('.btn-flash-clear').removeClass('hide');
                                    }
                                },
                            });
                        });
                    }
                });
            }
            var dates = $('#input-quote-request-dates');
            var datesChange = function () {
                if (!dates.val()) {
                    var minDate = new Date()
                    var endDate = new Date();
                    minDate.setTime(Date.now() + (0.25 * 3600 * 1000));
                    endDate.setTime(Date.now() + (5 * 24 * 3600 * 1000) + (0.25 * 3600 * 1000));
                    dates.attr('data-mindate', minDate.toISOString());
                    dates.attr('data-endDate', endDate.toISOString());
                }
            }
            dates.on('change daterange.clear', datesChange);
            datesChange();
            var options;
            var uploaded = false;
            edit_options = {
                language: locale,
                showClose: false,
                showUpload: false,
                showRemove: false,
                initialPreviewAsData: true,
                initialPreview: initialPreview,
                initialPreviewConfig: initialPreviewConfig,
                uploadAsync: true,
                overwriteInitial: false,
                allowedPreviewTypes: ['image', 'text', 'html', 'object', 'audio', 'video'],
                previewZoomSettings: {
                    image: {width: "initial", height: "auto"},
                    text: {width: "100%", height: "auto"},
                    object: {width: "100%", height: "auto"},
                    other: {width: "100%", height: "auto"},
                },
                uploadUrl: '/file/upload',
                deleteUrl: "/file/delete",
                maxFileSize: 3000,
                initialCaption: message_attachments,
                fileActionSettings: {
                    showUpload: true,
                    indicatorNew: '',
                },
                maxFileCount: 5,
                uploadExtraData: {
                    owner: owner,
                    id: attachments_id,
                    _token: $('input[name="_token"]').val(),
                },
                deleteExtraData: {
                    owner: owner,
                    id: attachments_id,
                    _token: $('input[name="_token"]').val(),
                },
                otherActionButtons: '<button type="button" class="kv-file-download btn btn-kv btn-default btn-outline-secondary" {dataKey}><i class="fa fa-download "></i></button>',
            };
            no_edit_options = {
                language: locale,
                showClose: false,
                showUpload: false,
                showRemove: false,
                initialPreviewAsData: true,
                initialPreview: initialPreview,
                initialPreviewConfig: initialPreviewConfig,
                uploadAsync: true,
                overwriteInitial: false,
                allowedPreviewTypes: ['image', 'text', 'html', 'object', 'audio', 'video'],
                previewZoomSettings: {
                    image: {width: "initial", height: "auto"},
                    text: {width: "100%", height: "auto"},
                    object: {width: "100%", height: "auto"},
                    other: {width: "100%", height: "auto"},
                },
                uploadUrl: special_entity ? '/file/upload' : null,
                deleteUrl: special_entity ? "/file/delete" : null,
                maxFileSize: 3000,
                initialCaption: message_attachments,
                fileActionSettings: {showRemove: false},
                maxFileCount: 5,
                uploadExtraData: {
                    owner: owner,
                    id: attachments_id,
                    _token: $('input[name="_token"]').val(),
                },
                deleteExtraData: {
                    owner: owner,
                    id: attachments_id,
                    _token: $('input[name="_token"]').val(),
                },
                otherActionButtons: '<button type="button" class="kv-file-download btn btn-kv btn-default btn-outline-secondary" {dataKey}><i class="fa fa-download "></i></button>',
            };
            var upint = setInterval(upload,100);
            function upload () {
                up = $('button.kv-file-upload:visible');
                uc = $('button.kv-file-upload.clicked:visible').size();
                if (up.size() && !uc) {
                    $(up[0]).addClass('clicked').click();
                }
            }
            if (edit) {
                options = edit_options;
                $('.fileinput:not(.readonly)').each(function (index) {
                    $(this).fileinput(options).on('fileloaded', function () {

                    }).on('fileuploaded', function (e, data, previewId, index) {
                        $('.btn-flash-clear').removeClass('hide');
                        setTimeout(add_download, 500);
                    });
                });
            } else {
                options = no_edit_options;
                $('.fileinput').each(function (index) {
                    if (!$(this).is('.readonly')) {
                        $(this).fileinput(options).on('filedisabled ', function () {
                            setTimeout(function () {
                                $('.file-input:eq(' + index + ')').find('.kv-file-remove').each(function () {
                                    key = $(this).data('key') + '';
                                    if (key && file_blocked.indexOf(key) > -1) {
                                        $(this).remove();
                                    }
                                });
                                if (!special_entity) {
                                    $('.file-input:eq(' + index + ')').find('.kv-file-zoom, .kv-file-download').attr('disabled', null);
                                    $('.file-input:eq(' + index + ')').find('.file-caption-main').hide();
                                }
                            }, 100);
                        }).fileinput('disable');
                        if (special_entity) {
                            $(this).fileinput('enable').on('fileloaded', function () {

                            }).on('fileuploaded', function (e, data, previewId, index) {
                                uploading = false;
                                $('.btn-flash-clear').removeClass('hide');
                                $('.file-input:eq(' + index + ')').find('.kv-file-remove.btn:not([data-key^=key_])').remove();
                                setTimeout(add_download, 500);
                            }).on('fileinputready', function (e) {
                                $('.file-input:eq(' + index + ')').find('.kv-file-remove.btn:not([data-key^=key_])').remove();
                            });
                        }
                    }
                })
            }
            $('.fileinput').each(function (index) {
                if ($(this).is('.readonly')) {
                    id = this.id;
                    options = no_edit_options;
                    options.initialPreview = eval(id + '_initialPreview;');
                    options.initialPreviewConfig = eval(id + '_initialPreviewConfig;');
                    $(this).fileinput(options).on('filedisabled ', function () {
                        setTimeout(function () {
                            $('.file-input:eq(' + index + ')').find('.kv-file-zoom, .kv-file-download').attr('disabled', null);
                            $('.file-input:eq(' + index + ')').find('.kv-file-remove').hide();
                            $('.file-input:eq(' + index + ')').find('.file-caption-main').hide();
                        }, 100);
                    }).fileinput('disable');
                };
            });
            var add_download = function () {
                $('.kv-file-download').click(function () {
                    id = $(this).data('key') + '';
                    temp = id.split('|');
                    if (temp.length > 1) {
                        o = temp[0];
                        id = temp[1];
                    } else {
                        o = owner;
                    }
                    if (id.indexOf('_') > 1) {
                        id = id.split('_')[1];
                        url = '/file/temp/' + o + '/' + temp_link + '/' + id + '/1';
                    } else {
                        url = '/file/get/' + o + '/' + id;
                    }
                    location.href = url;
                }).attr('data-download', 1);
            };
            add_download();
        }
        if ($('.btn-leave-entity').size()) {
            $('.btn-leave-entity').each(function (e) {
                $(this).click(function (e) {
                    var button = $(this);
                    var url = '/' + this.id.split('-').slice(1).join('/');
                    var form = $('<form method="post">').append($('input[name="_token"]:eq(0)')).attr('action',url);
                    $('#modal-default').ask(
                        'warning',
                        $(this).attr('data-question'),
                        $(this).attr('data-title'),
                        $(this).attr('data-confirm'),
                        $(this).attr('data-cancel'),
                        function () {
                            button.after(form);
                            button.parent().find('form')[0].submit();
                        }
                    );
                });
            });
        }
        if ($('#form-entity-edit, #form-entity-create').size() && $('#logo').size()) {
            options = {
                overwriteInitial: true,
                maxFileSize: 1500,
                showClose: false,
                showCaption: false,
                browseLabel: '',
                removeLabel: '',
                browseIcon: '<i class="glyphicon glyphicon-folder-open"></i>',
                removeIcon: '<i class="glyphicon glyphicon-remove"></i>',
                browseOnZoneClick: false,
                elErrorContainer: '#kv-avatar-errors-1',
                msgErrorClass: 'alert alert-block alert-danger',
                defaultPreviewContent: logo_defaultPreviewContent,
                layoutTemplates: {main2: '{preview} {remove} {browse}'},
                allowedFileExtensions: ["jpg", "png", "gif"],
                language: 'pt-BR',
            };
            $('.logoinput').fileinput(options)
        }
        entity_info();
        if (tipo == 'create') reset();
    });
})

