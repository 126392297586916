$(function () {
    /*Initialization*/
    $(document).ready(function () {
        cancel = $('.btn-cancel-subscription');
        var modal_cancel;
        var modal_csub;
        var eid;
        if (cancel.size()) {
            modal_cancel = $('#modal-cancel-subscription');
            canceled = false;
            cancel.click(function () {
                canceled = false;
                modal_cancel.modal('show');
            })
            modal_cancel.on('hidden.bs.modal', function () {
                if (canceled) {
                    form = $('form[id^="form-entity-"]');
                    form.attr('action','/entity/' + eid + '/subscription/cancel');
                    form[0].submit();
                }
            });
            $('.btn-confirm-cancel-subscription').click(function () {
                eid = $(this).data('id');
                canceled = true;
                $.pageOverlay.show();
                modal_cancel.modal('hide');
            })
        }
        if ($('[id^="btn-signup-"]').size()) {
            var modal_sub = $('#modal-subscription');
            var plan;
            var package;
            var entity;
            $('[id^="btn-signup-"]').click(function () {
                splan=$('#plan').val();
                eplan=$('#effective-plan').val();
                eval=$('#evaluation-plan').val();
                canc=parseInt($('#canceled').val());
                if (!splan || splan == 'basic' || eplan != splan || canc || eval) {
                    data = this.id.split('-');
                    plan = data[2];
                    package = data[3];
                    $('#plan-info').html($('#span-' + plan + '-title').html());
                    $('[id^="value-info-"]').html($('#span-' + plan + '-' + package).html());
                    $('#package-info').html($('#span-' + package + '-title').html());
                    modal_sub.find('[id^="div-"]').removeClass('has-error');
                    modal_sub.find('[id^="error-"]').addClass('hide');
                    $('[id^="pay-method-"]').each(function () {
                        this.checked = false;
                    });
                    modal_sub.modal('show');
                } else if (!canc) {
                    //TODO: Implementar regras para nova assinatura com assinatura vigente
                    modal_sub.notification('warning','', $('#subscription-active').val(), false, true);
                }
            });
            $('input[id^="card-number"]').inputmask({alias:"integer",integerDigits:16,rightAlign:0});
            $('input[id^="card-cvv"]').inputmask({alias:"integer",integerDigits:4,rightAlign:0});
            $('.btn-confirm-signup-subscription').click(function () {
                entity = $(this).data('id');
                var method = false;
                $('[id^="pay-method-"]').each(function () {
                   if (this.checked) {
                       method = this.value;
                   }
                });
                number = $('#card-number').val();
                cname = $('#card-name').val();
                doc = $('#card-doc')[0];
                month = $('#card-month').val();
                year = $('#card-year').val();
                cvv = $('#card-cvv').val();
                errors = false;
                if (!method) {
                    $('#div-pay-method').addClass('has-error');
                    $('#error-pay-method').removeClass('hide');
                    errors = true;
                }
                if (number.trim().length < 14) {
                    $('#div-card-number').addClass('has-error');
                    $('#error-card-number').removeClass('hide');
                    errors = true;
                }
                if (cname.trim().length < 5) {
                    $('#div-card-name').addClass('has-error');
                    $('#error-card-name').removeClass('hide');
                    errors = true;
                }
                if (!CPFCNPJ.validar(doc)) {
                    $('#div-card-doc').addClass('has-error');
                    $('#error-card-doc').removeClass('hide');
                    errors = true;
                }
                if (!month.length) {
                    $('#div-card-month').addClass('has-error');
                    $('#error-card-month').removeClass('hide');
                    errors = true;
                }
                if (!year.length) {
                    $('#div-card-year').addClass('has-error');
                    $('#error-card-year').removeClass('hide');
                    errors = true;
                }
                if (!year.length) {
                    $('#div-card-year').addClass('has-error');
                    $('#error-card-year').removeClass('hide');
                    errors = true;
                }
                if (cvv.trim().length < 3) {
                    $('#div-card-cvv').addClass('has-error');
                    $('#error-card-cvv').removeClass('hide');
                    errors = true;
                }
                $('#card-number').val(number.trim().replace(' ', ''));
                $('#card-name').val(cname.trim());
                $('#card-cvv').val(cvv.trim());
                if (!errors) {
                    signup(entity, plan, package, method, number, cname, doc.value, month, year, cvv);
                }
            });
            function signup(entity, plan, package, method, number, name, doc, month, year, cvv) {
                data = {
                    plan: plan,
                    package: package,
                    method: method,
                    number: number,
                    name: name,
                    doc: doc,
                    month: month,
                    year: year,
                    cvv: cvv,
                    _token: $('input[name="_token"]').val(),
                };
                $.pageOverlay.show();
                $.ajax({
                    url: '/entity/' + entity + '/subscription/signup',
                    method: 'post',
                    data: data,
                    success: function (data) {
                        $.pageOverlay.hide();
                        $('#modal-subscription').modal('hide');
                        if (data.status) {
                            $('#modal-default').success(data.message);
                            $.href('/entity/' + entity + '/edit#subscription-tab');
                        } else {
                            $('#modal-default').danger(data.message, 'Error', 'OK', function(){});
                        }
                    },
                    error:function (data) {
                        $.pageOverlay.hide();
                        $('#modal-subscription').modal('hide');
                        $('#modal-default').danger(data.responseText, 'Error', 'OK', function(){});
                    }
                });

            }
        };
    });
})

