$(document).ready(function () {
    var wonload = window.onload;
    window.onload = function (e) {
        if (wonload) {
            wonload(e);
        }
        /* Select tab */
        $.pageOverlay.hide();
        $('#input-location-hash').val(location.hash.substr(1));
        if($('[role="tabpanel"]').size()) {
            $('a[role="tab"]').click(function () {
                $('#input-location-hash').val(this.id);
                location.href = '#' + this.id;
            });
            if (location.hash) {
                if ($(location.hash).is(':visible')) {
                    if (!$(location.hash).is('[aria-expanded="true"]')) {
                        $(location.hash).tab('show');
                    }
                }
            }
        }
    };
    $('a[href]:not([href^="javascript:"],[href^="#"])').click(function () {
        $.pageOverlay.show();
    });
});
