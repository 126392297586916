$(function () {
    /*Initialization*/
    $(document).ready(function () {
        if ($('form[id^="form-entity-quote-"]').size()) {
            var calc_item = function (e) {
                index = this.id.split('-');
                index = index[index.length - 1];
                quantity = $('input[id^="input-item-quantity-' + index + '"]').val().replace(',', '.');
                price = $('input[id^="input-item-unit-price-' + index + '"]').val().replace(',', '.').replace('R$ ', '');
                if (quantity.length && price.length) {
                    quantity = parseFloat(quantity);
                    price = parseFloat(price);
                    result = quantity * price;
                    result = result.toString().replace('.', ',');
                } else {
                    result = '';
                }
                $('input[id^="input-item-total-' + index + '"]').val(result);
            };
            var check_items = function () {
                checked = $('.quote-item-check:checked').size();
                id = $('input[name="quote_id"]').val();
                if(!checked && !id) {
                    $('#quote-files').addClass('hide');
                } else {
                    $('#quote-files').removeClass('hide');
                }
                $('#quote-save-button').attr('disabled', checked || id ? null : 'disabled');
            };
            var check_submit = function (e) {
                id = $('input[name="quote_id"]').val();
                if (id) {
                    checked = $('.quote-item-check:checked').size();
                    disabled = $('input[name="disabled"]').val();
                    if (!checked && !disabled) {
                        $('#modal-default').ask(
                            'danger',
                            $('#delete_msg').val(),
                            $('#delete_title').val(),
                            $('#delete_ok').val(),
                            $('#delete_cancel').val(),
                            function () {
                                $('form[id^=form-entity-quote-]')[0].submit();
                            }
                        );
                        e.stopImmediatePropagation();
                        return false;
                    }
                }
                $('form[id^=form-entity-quote-]')[0].submit();
            };
            $(document).ready(function () {
                $('input[id^="input-expiration"]').inputmask({alias:"integer",integerDigits:3,suffix:' dias'});
                $('input[id^="input-item-quantity-"]').inputmask({alias:"numeric",digits:3,autoGroup:true,radixPoint:',',groupSeparator:'.',allowMinus:false});
                $('input[id^="input-item-unit-price-"]').inputmask({alias:"numeric",digits:3,autoGroup:true,radixPoint:',',groupSeparator:'.',prefix:'R$ ',digitsOptional:0,allowMinus:false});
                $('input[id^="input-item-total-"]').inputmask({alias:"numeric",radixPoint:',',groupSeparator:'.',prefix:'R$ ',autoGroup:true,digits:2,digitsOptional:!1});
                $('input[id^="input-item-shipping-"]').inputmask({alias:"numeric",digits:2,autoGroup:true,radixPoint:',',groupSeparator:'.',prefix:'R$ ',digitsOptional:0,allowMinus:false});
                $('input[id^="input-item-quantity-"], input[id^="input-item-unit-price-"]').change(calc_item);
                $('input[id^="input-item-quantity-"], input[id^="input-item-unit-price-"]').each(calc_item);
                $('input[id^="input-item-delivery-"]').inputmask({alias:"integer",integerDigits:3});

                $('#quote-save-button').click(check_submit);

                $('.quote-item-check').each(function () {
                    id = this.id.split('-')[2];
                    $('#item-data-' + id).find('input:not(.disabled)').attr('disabled', this.checked ? null : 'disabled');
                });

                $('.quote-item-check').on('ifToggled', function () {
                    id = this.id.split('-')[2];
                    $('#item-data-' + id).find('input:not(.disabled)').attr('disabled', this.checked ? null : 'disabled');
                    check_items();
                });
                check_items();

            });
        }
    })
});
