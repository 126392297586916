$(function () {
    $(document).ready(function () {
        /*Reset Password Modal*/
        if ($('#form-reset-password')) {
            var m = $('#modal-reset');
            var resetFields = function () {
                $('#div-input-reset-email').removeClass('has-error');
                $('#error-input-reset-email').addClass('hide');
                $('#error-input-reset-email').html('');
                $('#input-reset-email').val('');
            }
            var openModal = function () {
                resetFields();
                $('#input-reset-email').val($('#input-user-email').val());
                m.modal('show');
            }
            $('#reset-password-link').click(openModal);
            $('#btn-reset').click(function () {
                $.pageOverlay.show();
                if ($('#input-reset-email').val().trim()) {
                    m.modal('hide');
                }
                data = {email: $('#input-reset-email').val(),'_token': $('[name="_token"]').val()};
                resetFields();
                $.ajax({
                    url: '/password/email',
                    method: 'post',
                    data: data,
                    success: function (data) {
                        $.pageOverlay.hide();
                        if (!data.status) {
                            $('#div-input-reset-email').addClass('has-error');
                            $('#error-input-reset-email').removeClass('hide');
                            $('#error-input-reset-email').html(data.message);
                            if (!m.hasClass('in')) {
                                m.modal('show');
                            }
                        } else {
                          m.modal('hide');
                          $('#modal-default').success(data.message, data.title, data.dismiss);
                        }
                    },
                    error:function (data) {
                        $.pageOverlay.hide();
                        $('#modal-reset').modal('hide');
                      $('#modal-default').danger(data.responseText);
                    }
                });
            });
        }
        /*Activation*/
        if ($('#modal-inactive').size()) {
            $('#modal-inactive').modal('show');
            $('#btn-send').click(function () {
                $('#modal-inactive').modal('hide');
                data = $('#form-send-activation').serialize();
                $.ajax({
                    url: '/activate',
                    method: 'post',
                    data: data,
                    success: function (data) {
                      $('#modal-default').alert(data.severity, data.message, data.title, data.dismiss);
                    },
                    error:function (data) {
                      $('#modal-default').danger('Error sending data. Try again later.' + data.responseText, 'Error', 'Close');
                    }
                });
            });
        }
    });
});
