$(function () {
    $(document).ready(function () {
        if ($('[id^="form-user-"]').size()) {
            var roles = $('#user-roles').find('input[id^="role-"]:not([disabled="disabled"])');
            var checkType = function (e) {
                target = e && (e.currentTarget) ? e.currentTarget : (e && e.srcElement ? e.srcElement : null);
                id = target ? target.id : null;
                var self = $(this);
                dflt = self.attr('data-default') && self.attr('data-default').toLowerCase() == 'true';
                excl = self.attr('data-exclusive') && self.attr('data-exclusive').toLowerCase() == 'true';
                b = $('#role-buyer');
                s = $('#role-seller');
                buyer = b.is(':checked');
                seller = s.is(':checked');
                open_quotes = $('#open_quotes').val();
                open_quote_requests = $('#open_quote_requests').val();
                if (id == 'role-seller' && !seller && open_quotes > 0) {
                    $('#modal-default').danger(
                        $('#open_quotes').attr('data-message'),
                        $('#open_quotes').attr('data-title'),
                        $('#open_quotes').attr('data-dismiss'),
                        'void()'
                    );
                    setTimeout(function () {
                        s.iCheck('check');
                    }, 10);
                    return false;
                }
                if (id == 'role-buyer' && !buyer && open_quote_requests > 0) {
                    $('#modal-default').danger(
                        $('#open_quote_requests').attr('data-message'),
                        $('#open_quote_requests').attr('data-title'),
                        $('#open_quote_requests').attr('data-dismiss'),
                        'void()'
                    );
                    setTimeout(function () {
                        b.iCheck('check');
                    }, 10);
                    return false;
                }
                if (excl && self[0].checked) {
                    roles.filter(':checked').each(function () {
                       i = $(this);
                       if (i[0].id != self[0].id) {
                           i.iCheck('uncheck');
                       }
                    });
                } else if(self[0].checked)  {
                    roles.filter(':checked').each(function () {
                        i = $(this);
                        e = i.attr('data-exclusive') && i.attr('data-exclusive').toLowerCase() == 'true';
                        if (i[0].id != self[0].id && e) {
                             i.iCheck('uncheck');
                        }
                    });
                }
                if (!roles.filter(':checked').size() && $('#role-admin').attr('type') != 'hidden') {
                    d=roles.filter('[data-default="true"]:eq(0)');
                    ne=roles.filter(':not([data-exclusive="true"])').find(':eq(0)').iCheck('check');
                    f=roles.filter(':eq(0)');
                    var t;
                    if (!d.size() && !ne.size()) {
                        t=f;
                    } else if (!d.size()) {
                        t=ne;
                    } else {
                        t=d;
                    }
                    setTimeout(function(){t.iCheck('check')});
                }
            };
            roles.on('ifToggled click keypress', checkType);
        }
        if ($('[id^="form-profile-"]').size() && $('#avatar').size()) {
            options = {
                overwriteInitial: true,
                maxFileSize: 1500,
                showClose: false,
                showCaption: false,
                browseLabel: '',
                removeLabel: '',
                browseIcon: '<i class="glyphicon glyphicon-folder-open"></i>',
                removeIcon: '<i class="glyphicon glyphicon-remove"></i>',
                browseOnZoneClick: false,
                elErrorContainer: '#kv-avatar-errors-1',
                msgErrorClass: 'alert alert-block alert-danger',
                defaultPreviewContent: user_defaultPreviewContent,
                layoutTemplates: {main2: '{preview} {remove} {browse}'},
                allowedFileExtensions: ["jpg", "png", "gif"],
                language: 'pt-BR',
            };
            $('.avatarinput').fileinput(options);
        }
    });
});
