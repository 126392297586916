$(function () {
    /*Initialization*/
    $(document).ready(function () {
        if ($('#div-quote-questions').size()) {
            var loadQuestions = function (message, title) {
                if ($('input[name="quote_request_id"]').val() || $('input[name="quote_request"]').val()) {
                    $('.questions-info-overlay').show();
                    $.ajax({
                        method: 'get',
                        url: '/questions/' + ($('input[name="quote_request_id"]').val() ? $('input[name="quote_request_id"]').val() : $('input[name="quote_request"]').val()),
                        success: function (data) {
                            $('.questions-info-overlay').hide();
                            questions = $('#div-questions');
                            questions.find(':visible').remove();
                            if (data.questions.length) {
                                questions.removeClass('hide');
                                $('#div-no-questions').addClass('hide');
                                model_question = questions.find('.panel-question');
                                model_answer = questions.find('.panel-answer');
                                model_answer_textarea = questions.find('.panel-answer-textarea');
                                for (i = 0; i < data.questions.length; i++) {
                                    id = data.questions[i].message.id;
                                    content = data.questions[i].message.content;
                                    sender = data.questions[i].message.sender_name;
                                    temp = data.questions[i].message.send_date.split(' ');
                                    d = temp[0].split('-');
                                    h = temp[1].split(':');
                                    date = d[2] + '/' + d[1] + '/' + d[0] + ' ' + h[0] + ':' + h[1];
                                    question = model_question.clone();
                                    question.removeClass('hide');
                                    question.removeClass('panel-question');
                                    qlink = question.find('.question-link');
                                    qlink.attr('href', '#qcollapse' + id);
                                    span_icon = qlink.find('.span-question-icon');
                                    span_date = qlink.find('.span-question-date');
                                    if (sender !== undefined && model_answer_textarea.size()) {
                                        content += ' - ' + '<span class="text-muted">' + sender + "</span>";
                                    }
                                    span_icon.append(content);
                                    span_date.append('- ' + date);
                                    answers = data.questions[i].answers.length;
                                    questions.append(question);
                                    btn_delete = question.find('.span-question-delete>i');
                                    btn_delete.attr('data-question-id', id);
                                    if (answers) {
                                        btn_delete.removeClass('text-danger').addClass('text-muted disabled');
                                        span_icon.find('i').addClass('text-blue');
                                        for (j = 0; j < answers; j++) {
                                            answer = model_answer.clone();
                                            answer.removeClass('panel-answer');
                                            content = data.questions[i].answers[j].message.content;
                                            temp = data.questions[i].answers[j].message.send_date.split(' ');
                                            sender = data.questions[i].answers[j].message.sender_name;
                                            d = temp[0].split('-');
                                            h = temp[1].split(':');
                                            date = d[2] + '/' + d[1] + '/' + d[0] + ' ' + h[0] + ':' + h[1];
                                            span_icon = answer.find('.span-answer-icon');
                                            span_date = answer.find('.span-answer-date');
                                            if (sender !== undefined && model_answer_textarea.size()) {
                                                content += ' - ' + '<span class="text-muted">' + sender + "</span>";
                                            }
                                            span_icon.append(content);
                                            span_date.append('- ' + date);
                                            answer.attr('id', 'qcollapse' + id);
                                            answer.removeClass('hide');
                                            question.append(answer);
                                        }
                                    }
                                    btn_delete.click(function () {
                                        var btn = $(this);
                                        $('#modal-default').ask(
                                            'danger',
                                            $('#msg-question-delete').html(),
                                            $('#msg-question-title').html(),
                                            $('#msg-question-ok').html(),
                                            $('#msg-question-cancel').html(),
                                            function () {
                                                $.ajax({
                                                    method: 'delete',
                                                    url: '/entity/' + $('input[name="entity_id"]').val() + '/request/' + $('input[name="quote_request_id"]').val() + '/question/' + btn.attr('data-question-id'),
                                                    data: {
                                                        _token: $('input[name="_token"]').val(),
                                                    },
                                                    success: function (data) {
                                                        if (data.success == 1) {
                                                            loadQuestions();
                                                        } else {
                                                            // Show error
                                                        }
                                                    }
                                                });
                                            }
                                        );
                                    });

                                    textarea = model_answer_textarea.clone();
                                    textarea.attr('id', 'qcollapse' + id).find('textarea').attr('name', 'answers[]');
                                    textarea.removeClass('hide');
                                    question.append(textarea);
                                    question.append($('<input type="hidden" name="question_ids[]">').val(id));

                                }
                            } else {
                                $('#div-no-questions').removeClass('hide');
                                questions.addClass('hide');
                            }
                            textareas = $('.panel-answer-textarea:visible');
                            if (textareas.size()) {
                                $('#div-save-answers').removeClass('hide');
                                textarea.removeClass('panel-answer-textarea');
                            } else if ($('[name="answers[]"]').size() == 0) {
                                $('#div-save-answers').addClass('hide');
                            }
                            if (message && title) {
                                $('#modal-default').success(message, title);
                            }
                        },
                        error: function () {
                            $('.questions-info-overlay').hide();
                        }
                    });
                }
            };
            question = $('#text-question');
            if (question.size()) {
                var checkQuestion = function () {
                    btn_question = $('#btn-question');
                    text = $('#text-question').val().trim();
                    if (text.length && btn_question.attr('disabled') == 'disabled') {
                        btn_question.attr('disabled', null);
                    } else if (!text.length) {
                        btn_question.attr('disabled', 'disabled');
                    }
                };
                $('#btn-question').click(function () {
                    $data = {
                        entity_id: $('input[name="entity_id"]').val(),
                        question: $('#text-question').val(),
                        _token: $('input[name="_token"]').val(),
                    };
                    quote_id = $('input[name="quote_id"]').val();
                    if (quote_id) {
                        $data.quote_id = quote_id;
                    }
                    $('.questions-info-overlay').show();
                    $.ajax({
                        method: 'post',
                        url: '/question/' + $('input[name="quote_request_id"]').val(),
                        data: $data,
                        success: function (data) {
                            $('.questions-info-overlay').hide();
                            if (data.success == 1) {
                                $('#text-question').val('');
                                loadQuestions();
                            } else {
                                // Show error
                            }
                        },
                        error: function () {
                            $('.questions-info-overlay').hide();
                        }
                    });
                });
                $('#text-question').keyup(checkQuestion);
                checkQuestion();
            }
            loadQuestions();
            $('#btn-save-answers').click(function () {
                var question_ids = [];
                $('[name="question_ids[]"]').each(function () {
                    question_ids[question_ids.length] = this.value;
                });
                var answers = [];
                $('[name="answers[]"]').each(function () {
                    answers[answers.length] = this.value;
                });
                var ref = $(this);
                $('.questions-info-overlay').show();
                $.ajax({
                    method: 'post',
                    url: '/entity/' +  $('input[name="entity_id"]').val() + '/request/' + $('input[name="quote_request_id"]').val() + '/answer',
                    data: {
                        quote_request_id: $('[name="quote_request_id"]').val(),
                        answers: answers,
                        question_ids: question_ids,
                        _token: $('input[name="_token"]').val()
                    },
                    success: function (data) {
                        $('.questions-info-overlay').hide();
                        $('.questions-info-overlay').hide();
                        if (data.success == 1) {
                            if (data.count) {
                                loadQuestions(ref.data('message'), ref.data('title'))
                            } else {
                                loadQuestions();
                            }
                        } else {
                            // Show error
                        }
                    },
                    error: function () {
                        $('.questions-info-overlay').hide();
                    }
                });
            });
        }
    });
});
