$(function () {
    $(document).ready(function () {
        if ($('.telephone').size()) {
            var maskList = $.masksSort($.masksLoad("/plugins/inputmask-multi/data/phone-codes.json"), ['#'], /[0-9]|#/, "mask");
            var maskOpts = {
                inputmask: {
                    definitions: {
                        '#': {
                            validator: "[0-9]",
                            cardinality: 1
                        }
                    },
                    showMaskOnHover: false,
                    autoUnmask: true,
                    clearMaskOnLostFocus: false
                },
                match: /[0-9]/,
                replace: '#',
                list: maskList,
                listKey: "mask",
                onMaskChange: function(maskObj, determined, event) {
                    target = null;
                    if (undefined !== event) {
                        target = (event.currentTarget) ? event.currentTarget : event.srcElement;
                    }
                    if (determined) {
                        var hint = maskObj.name_en;
                        if (maskObj.desc_en && maskObj.desc_en != "") {
                            hint += " (" + maskObj.desc_en + ")";
                        }
                        if (target) {
                            $(target).parent().parent().find('>span').html(hint);
                        }
                    } else {
                        if (target) {
                            $(target).parent().parent().find('>span').html('');
                        }
                    }
                }
            };
            var applyTelephoneMask = function(target) {
                target = target ? target : $('body');
                tel = target.find('.telephone:not([name="telephone[]"])');
                if (tel.size()) {
                    tel.inputmask('remove');
                    tel.inputmasks(maskOpts);
                }
                target.find('.telephone-extension:not([name="extension[]"])').inputmask('[99999]');
            }
            applyTelephoneMask();
            list = $('[id^="telephone-list"]:eq(0)');
            if (list.size()) {
                var addTelephone;
                var removeTelephone;
                var limit = 4;
                var parent = list.parent();
                parent.find('div[id^="telephone-list-"]').children().remove();
                var updateButtons = function () {
                    size = list.children().size();
                    if (size == 1) {
                        list.find('.telephone-add').removeClass('hide');
                        list.find('.telephone-remove').addClass('hide');
                    } else {
                        list.find('.telephone-add').addClass('hide');
                        if (size < limit) {
                            list.find('.telephone-add:last').removeClass('hide');
                        }
                        list.find('.telephone-remove').removeClass('hide');
                    }
                };
                var updateIndexes = function () {
                    list.children().each(function (index) {
                        $(this).find('input[name^="telephone["]').attr('name', 'telephone[' + index + ']').attr('id','input-telephone-' + index);
                        $(this).find('input[name^="extension["]').attr('name', 'extension[' + index + ']').attr('id','input-extension-' + index);
                        $(this).find('>div>span').attr('id', 'info-input-telephone-' + index);
                    })
                };
                addTelephone = function (e,number,extension,errorTel,errorExt) {
                    if (list.children().size() < limit) {
                        model=parent.find('div[id^="telephone-model-"]').clone(true).removeClass('hide').attr('id',null);
                        num=model.find('input[name^="telephone["]').attr('disabled',null);
                        if (undefined !== number) {
                            num.val(number);
                        }
                        ext=model.find('input[name^="extension["]').attr('disabled',null);
                        if (undefined !== extension) {
                            ext.val(extension);
                        }
                        model.find('.telephone-add').unbind('click').click(addTelephone);
                        model.find('.telephone-remove').unbind('click').click(removeTelephone);
                        list.append(model);
                        updateIndexes();
                        if (errorTel) {
                            num.parent().attr('id','div-' + num.attr('id')).addClass('has-error');
                            num.find('~span').attr('id','error-' + num.attr('id')).html(errorTel);
                        }
                        if (errorExt) {
                            ext.parent().attr('id','div-' + num.attr('id')).addClass('has-error');
                            ext.find('~span').attr('id','error-' + num.attr('id')).html(errorExt);
                        }
                        applyTelephoneMask(model);
                        updateButtons();
                        model.cleanErrors();
                    }
                };
                removeTelephone = function (e) {
                    if (list.children().size() < 2) {
                        return false;
                    }
                    else {
                        $(this).parent().parent().remove();
                        updateIndexes();
                        updateButtons();
                    }
                };
                if (!parent.find('div[id^="telephone-list-"]').children().size()) {
                    var addTel = $('input.telephone-add');
                    var addExt = $('input.extension-add');
                    if (addTel.size()) {
                        addTel.each(function (index) {
                            addTelephone(
                                null,
                                this.value,
                                addExt[index].value,
                                $(addTel[index]).attr('data-error'),
                                $(addExt[index]).attr('data-error')
                            );
                        });
                    } else {
                        addTelephone();
                    }
                }
            }
        }
    })
});
