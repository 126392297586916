$(function () {
    $(document).ready(function () {
        if ($('[id^="opening-list"]:eq(0)').size()) {
            var list = $('[id^="opening-list"]:eq(0)')
            var limit = 5;
            var parent = list.parent();
            var removeSiblings;
            var addDate;
            var clearDate;
            var changeDate;
            var updateButtons;
            var updateIndexes;
            var updateMinDates;
            removeSiblings = function (node, self) {
                if (self) {
                    self = node;
                }
                node = node.next();
                if (node.size()) {
                    node.find('.datetime-clear').trigger('click');
                    node.remove();
                }
                if (self) {
                    self.find('.datetime-clear').trigger('click');
                    self.remove();
                }
            };
            addDate = function (e, opening, error, index) {
                if (list.children().size() < limit) {
                    model = parent.find('div[id^="opening-model"]').clone(true).removeClass('hide').attr('id', null);
                    d = model.find('input[name^="openings["]').attr('disabled', null).attr('text-format', 'daterange').on('daterange.change', changeDate);
                    if (undefined !== opening && opening) {
                        d.val(opening);
                    }
                    model.find('input[name^="openings["]').on('apply.daterangepicker', changeDate);
                    model.find('.opening-add').off('click').click(addDate);
                    model.find('.datetime-clear').off('click').click(clearDate);
                    list.append(model);
                    if (error) {
                        d.attr('id', d.attr('id') + '-' + index);
                        d.parent().parent().attr('id', 'div-' + d.attr('id')).addClass('has-error');
                        d.parent().find('~span').attr('id', 'error-' + d.attr('id')).html(error);
                    }
                    model.cleanErrors();
                    updateButtons();
                    updateIndexes();
                    updateMinDates(true);
                    $(window).resize();
                }
            };
            changeDate = function (e) {
                removeSiblings($(this).parent().parent().parent());
                updateButtons();
            };
            clearDate = function (e) {
                removeSiblings($(this).parent().parent().parent());
                $(this).addClass('hide');
                updateButtons();
                updateIndexes();
                updateMinDates();
            };
            updateButtons = function () {
                c = list.children()
                size = c.size();
                if (size == 1 && c.find(':eq(0)').val()) {
                    list.find('.opening-add').removeClass('hide');
                } else {
                    list.find('.opening-add').addClass('hide');
                }
                var dates = true;
                list.find('input[name^="openings["]').each(function () {
                    if (!$(this).val()) {
                        dates = false;
                    }
                });
                if (size < limit && dates) {
                    list.find('.opening-add:last').removeClass('hide');
                } else {
                    list.find('.opening-add').addClass('hide');
                }
            };
            updateIndexes = function () {
                var items = [];
                list.children().each(function (index) {
                    item = $(this).find('input[name^="openings["]');
                    item.attr('name', 'openings[' + index + ']').attr('id', 'input-opening-' + index);
                });
            };
            updateMinDates = function (start) {
                var setstart = start;
                list.find('input[name^="openings["]:visible').each(function () {
                    p = $(this).parent().parent().parent().prev().find('input[name^="openings["]');
                    if (!p.size()) {
                        now = new Date((new Date()).getTime() + 600000);
                        dt = ' - ' + now.getDate() + '/' + (now.getMonth() + 1) + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes();
                        dt = dt.split(' - ');
                    } else {
                        dt = p.val().split(' - ');
                    }
                    if (dt.length == 2) {
                        ed = dt[1];
                        ep = ed.split(' ');
                        if (ep.length == 2) {
                            epd = ep[0].split('/');
                            ept = ep[1].split(':');
                            minDate = new Date(epd[2], epd[1] - 1, epd[0], ept[0], ept[1]);
                            minDate = new Date(minDate.getTime() + 60000);
                            d.attr('data-minDate', minDate);
                            if (setstart) {
                                startDate = new Date();
                                startDate.setTime(minDate.getTime());
                                d.attr('data-startDate', startDate);
                            }
                        }
                    }
                });
            };
            var add = $('input.opening-add');
            if (add.size()) {
                add.each(function (index) {
                    addDate(
                        null,
                        this.value,
                        $(add[index]).attr('data-error'),
                        index
                    );
                });
            }
            var dates = $('#input-quote-request-dates');
            var focusDates = function () {
                if ($(this).val() && !parent.find('div[id^="opening-list"]').children().size()) {
                    addDate();
                }
            }
            dates.on('focus.openings', focusDates);
            dates.on('apply.daterangepicker', function () {
                removeSiblings(list.find('input[name^="openings["]:eq(0)').parent().parent().parent(), true);
                addDate();
            });
            dates.on('daterange.change', function () {
                if (dates.val().length) {
                    $(this).parent().find('.datetime-clear').removeClass('hide');
                }
            });
            dates.on('daterange.clear', function () {
                removeSiblings(list.find('input[name^="openings["]:eq(0)').parent().parent().parent(), true);
                $(this).parent().find('.datetime-clear').addClass('hide');
            });
            list = $(document).find('div[id^="opening-list"]');
            if (list.size() && !list.children().size()) {
                addDate();
            }
        }
    });
});
